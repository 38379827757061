<template>
  <div class="intent-section px-7">
    <payment-process :contactID="contact_id" :propertyID="property_id" :leaseID="lease_id" paymentSource="MOVE_OUT"></payment-process>
    <div class="new-tenant-walkthrough-footer" >
      <v-row>
        <v-col class="pt-4">
          <help-juice />
        </v-col>
        <v-col cols="11" class="d-flex align-center justify-end pr-sm-7">
          <hb-link class="mr-3" @click="$emit('close')">Cancel</hb-link>
          <hb-btn color="secondary" class="mr-3" v-if="hasPermission('move_out_skip_payment')" @click="$emit('next')">Skip Without Payment</hb-btn>
          <hb-tooltip v-else>
            <template v-slot:item >
              <hb-btn color="secondary" class="mr-3" disabled>Skip Without Payment</hb-btn>
            </template>
            <template v-slot:body> You do not have the permission to skip payment </template>
          </hb-tooltip>
          <hb-btn class="mr-3" color="secondary" :disabled="totalDue <= 0" @click="writeOffModal = true">Write Off as a Loss</hb-btn>
          <hb-btn color="primary" v-if="totalDue <= 0" @click="$emit('next')" >Next</hb-btn>
          <hb-btn v-if="inCall && ivrPaymentType === 'card' &&  IvrPaymentEnable && ivrPaymentMethod == 'new' " color="primary" @click="completeSession()" :disabled="!enableIvrProcessPayment" :loading="ivrPaymentButtonLoading">Process Payment ivr {{ivrTimeout}}</hb-btn>
          <hb-btn color="primary" v-else-if="auction_status !== 'move_out'" @click="checkPaymentsErrors" :disabled="isLoading($options.name) || disable_payment" :loading="disable_payment" >Process Payment</hb-btn>
        </v-col>
      </v-row>
    </div>
    <hb-modal
      v-model="writeOffModal"
      size="medium"
      title="Write Off as Loss"
      @close="writeOffModal = false"
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          Are you sure you want to write off the current balance of {{totalDue | formatMoney}} as a loss?
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn
          color="primary"
          @click="writeOff"
        >
          Write Off Balance
        </hb-btn>
      </template>
    </hb-modal>
    <payments-notification-modal
        v-model="notificationModal"
        notificationType="accept_payments"
    >
    </payments-notification-modal>
  </div>

</template>
<script type="text/babel">

import api from '../../../assets/api.js'
import moment from 'moment'
import CollectPayment from '../CollectPayment.vue'
import { EventBus } from '../../../EventBus.js';
import Loader from '../../assets/CircleSpinner.vue';
import PaymentProcess from '../PaymentProcess/Index.vue';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
import PaymentsNotificationModal from '../../payments/PaymentsNotificationModal.vue';
import axios from 'axios';
import https from 'https';

export default {
  name: 'IntentPayments',
  mixins: [ notificationMixin ],
  data: () => ({
    payment_methods: [],
    payment_method: {},
    payment_amount: 0,
    payment: {},
    dataLoading: false,
    leases: [],
    formErrors:undefined,
    writeOffModal: false,
    panel: [0],
    disable_payment: false,
    notificationModal: false
  }),
  props: ['close', 'next', 'lease_id', 'unit_id', 'property_id', 'contact_id', 'showChat','auction_status' ],
  components: {
    CollectPayment,
    Loader,
    PaymentProcess,
    PaymentsNotificationModal
  },
  filters:{
    totalLeaseDue(lease){
      let lease_total = lease.OpenInvoices.reduce((a,b) => a + b.balance , 0) ;
      let new_charges = lease.Charges && lease.Charges.balance ?  lease.Charges.balance : 0;

      return Math.round((lease_total + new_charges) * 1e2) / 1e2;
    }
  },
  computed: {
      inCall() {
              return this.notification.status === 'active';
            },
          ivrTimeout(){
              return this.ivrTimer
          },
    ...mapGetters({
      ivrTimer:'charmCallStore/ivrTimer',
      ivrPaymentButtonLoading:'charmCallStore/ivrPaymentButtonLoading',
      paymentConnectorResponse:'charmCallStore/paymentConnectorResponse',
      enableIvrProcessPayment:'charmCallStore/enableIvrProcessPayment',
      ivrPaymentMethod:'charmCallStore/ivrPaymentMethod',
      IvrPaymentEnable:'charmCallStore/IvrPaymentEnable',
      ivrPaymentType:'charmCallStore/ivrPaymentType',
      paymentData: 'paymentsStore/getPaymentInformation',
      notification:'charmCallStore/notification',
      payment_information: 'paymentsStore/getPaymentInformation',
      invoices: 'paymentsStore/getInvoices',
      getErrors: 'paymentsStore/getErrors',
      hasPermission: 'authenticationStore/rolePermission'
    }),
    
    totalDue(){
      if(this.payment_information.totalDue !== null && this.payment_information.totalDue <= 0) {
        this.$emit('next',false)
      }
      return this.payment_information.totalDue;
    },
   
  },
  async created(){
    await this.fetchPropertyConnections({propertyID: this.property_id});
    this.updateIvrPaymentEnable(true)
    this.$emit('showChat');
    EventBus.$on('paymentReader', this.enablePayment);
  },
  destroyed(){
    EventBus.$off('paymentReader', this.enablePayment);
    this.closeMessageNotification();
    this.updateIvrPaymentEnable(false)
  },
  methods: {
    async completeSession(){
      EventBus.$emit('complete-ivr-payment-session');   
          },
    ...mapActions({
      fetchPropertyConnections: 'paymentsStore/fetchPropertyConnections',
      getTransformedPaymentObject: 'paymentsStore/getTransformedPaymentObject',
      payInvoice: 'onBoardingStore/payInvoice',
      payInvoicePAXLAN: 'onBoardingStore/payInvoicePAXLAN',
    }),
    ...mapMutations({
      updateIvrPaymentEnable:'charmCallStore/updateIvrPaymentEnable',
      setCheckErrors: 'paymentsStore/setCheckErrors',
      checkOnlyCredit:'paymentsStore/checkOnlyCredit'
    }),
    async enablePayment(response) {
      this.disable_payment = false;
      if(response && response.error) {
        this.showMessageNotification({ list: [response.error] });
      } else if(response && response.data && response.data.msg) {
        this.showMessageNotification({ type: 'success', description: response.data.msg.text });
        this.$emit('next')
        EventBus.$emit('payment_saved');
        this.collect_payments++;
      }
    },
    async fetchPropertyConnectionsbyID(device_id,property_id) {
      let r = await api.get(this.$app, api.PROPERTIES + property_id + "/connections/address?device_id=" + device_id);
      this.IPaddress = r.IPaddress;
      this.lan = r.lan;
    },

    checkPaymentsErrors() {
      //this.setCheckErrors({ value: true });
      //this function will check if the payment only with credit it will skip the checkErrors. otherwise, if not credit payment it wich check the errors normal.
      if (this.hasPermission("accept_payments")){
        this.checkOnlyCredit();
      } else {
          this.notificationModal = true;
      }
    },
    async processPayment(){
      EventBus.$emit('disable_reader_dropdown');
      this.disable_payment = true;
      const paymentInfo = await this.getTransformedPaymentObject({
        id: this.$options.name,
        formErrors: this.formErrors
      });

      if(paymentInfo.errors && paymentInfo.errors.length) {
        this.showMessageNotification({ list: paymentInfo.errors });
        this.disable_payment = false;
        EventBus.$emit('enable_reader_dropdown');
        return;
      }

      if(paymentInfo && paymentInfo.payment) {
        console.log("********In-Moveout********")
        let response = {};
        let payloadResponse;
        let lanResponseData;
        let payment_id;
        let responseData;
        //Fetch Device IP and Port for Lan Connection
        if(paymentInfo?.paymentMethod?.device_id)
          await this.fetchPropertyConnectionsbyID(paymentInfo.paymentMethod.device_id, paymentInfo.property_id);
        try {
          if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1){
				  // Create an instance of the HTTPS agent with certificate verification enabled.
                      const certData = `-----BEGIN CERTIFICATE-----
MIIDzTCCArWgAwIBAgIBAjANBgkqhkiG9w0BAQsFADBtMQswCQYDVQQGEwJVUzEL
MAkGA1UECAwCRkwxDDAKBgNVBAcMA0pBWDEMMAoGA1UECgwDUEFYMRQwEgYDVQQL
DAtJTlRFR1JBVElPTjEfMB0GA1UEAwwWUEFYVVMgVEVSTUlOQUwgUk9PVCBDQTAe
Fw0xODA4MDYwNzMyMTJaFw0yODA4MDMwNzMyMTJaMGoxCzAJBgNVBAYTAlVTMQsw
CQYDVQQIDAJGTDEMMAoGA1UECgwDUEFYMRQwEgYDVQQLDAtJTlRFR1JBVElPTjEq
MCgGA1UEAwwhUEFYVVMgVEVSTUlOQUwgU0VSVkVSIENFUlRJRklDQVRFMIIBIjAN
BgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAwbJg+g0N/M+ohU4sTpNHY25j0tH3
6f6kk5M564g6/kBv8WWL/4QnpLHfGVFdBC+P30JW9QjOGJj5OY5suQm0xqs6/rD1
qb1cWbBaeTvOMmx+79uIuQweRAfbAOdc3RdKSz3nR/IfZ1059FG23/8IF9/pRXuM
oeFl4MzWwoxkqJ24Q216aD+fJvGNjoajuBbnyM+5WQDRqBE+GI04ElWDDLLft/Kx
cQwL/gjxFeGSmDg88yHPcimcIulnGnmc6M3SV5RbGVHbCtOwzwjQdUndom9lDZ82
VkBPUaYgiMnFzbbYF9FHFP2xD93qvWrBpc5GGQleDsH8LLHsXbZdPbvdnwIDAQAB
o3sweTAJBgNVHRMEAjAAMAsGA1UdDwQEAwIFoDAdBgNVHQ4EFgQUuWH25D/quAew
5neUSAjaqxe6LCUwHwYDVR0jBBgwFoAUKyQA82I7zBIeEmhqHya07aPrLHYwHwYD
VR0RBBgwFoIIKi5wYXgudXOHBH8AAAGHBMCoYnIwDQYJKoZIhvcNAQELBQADggEB
ADBQHlhlEHzmyVLiAoInaxbP7s8hN29VcWXUQXo5VrbfWCB26zfY0mFgZezMa7VB
dzzflJ/XeqDs+HgQ5KQ5cXL4PO+jpFvPLPj7wfy02YdyjuKu/EDMkrxLXwJm8TJY
qeVd/W5phCxqXc/+5jvvrGGdOTd+tgpz/DndKl4mdboqWeqCJxVWbxZez2FDrUzP
cPHhEt+gfJFr2ARYhPwf7jD5/97YV2ULeYxoOkr+qF5GdTdSxn944FM8l8ZGrRpe
rkhYcw+B0V6MgdoFGhkBDXbos0KiUUbLVgCyf+ewUQuc1+2q9++s+amJ0svydr1V
0gVZN3O9nql8n0IcpWtsyvQ=
-----END CERTIFICATE-----`;
            const httpsAgent = new https.Agent({
						  ca: [certData],
						});
            // Configure Axios to use the custom HTTPS agent.
						const instance = axios.create({
						  httpsAgent
						});
            const endpoint = 'https://' + this.IPaddress + '?';
            //Get PAX Request Payload from HB Backend server
            payloadResponse = await api.post(this, api.PAYMENTS + "getLanSwipePayload",paymentInfo);
            payment_id = payloadResponse.payment.id;
            paymentInfo.payment.id = payment_id;
			      // Make the GET request using the custom Axios instance.
			      lanResponseData = await instance.get(endpoint + payloadResponse.payment.status_desc , {headers: {'Accept': 'text/plain',},});
            paymentInfo.payment.PAXResponse = lanResponseData;
            responseData = await this.payInvoicePAXLAN(paymentInfo);
          } else {
              responseData = await this.payInvoice(paymentInfo);
          }
          response = {
              data: {
                msg: {
                  id: this.$options.name,
                  text: responseData.msg,
                },
                payment: responseData.payment
              }
          };
          EventBus.$emit('lease_edited');
          EventBus.$emit('refresh_lease_tenants');
          EventBus.$emit('enable_reader_dropdown');
        } catch(err){
          response = {
            error: err,
            contact_id: paymentInfo.contact_id
          };
          if(paymentInfo.paymentMethod && paymentInfo.paymentMethod.device_id && this.lan == 1)
          {
            responseData = await api.post(this, api.PAYMENTS + payment_id + "/lanSwipeResponse", response);
          }    
        } finally {
          EventBus.$emit('enable_reader_dropdown');
          this.enablePayment(response);
          EventBus.$emit('enable_reader_dropdown');
        }
      }      
    }, 
    
    writeOff(){
      let invoice_ids = [];
      for(let j = 0; j < this.invoices.length; j++) {
        invoice_ids.push({invoice_id: this.invoices[j].id});
      }

      let data = {
          amount: this.totalDue,
          notes: '',
          invoices: invoice_ids,
          contact_id: this.$props.contact_id
      }
      
      return api.post(this, api.LEASES + this.lease_id + '/invoices/write-off', data).then(r => {
        this.writeOffModal = false
        this.$emit("next");
      }).catch(err => {
        this.showMessageNotification({type: 'error', description: err});
      });
    }
  },
  watch: {
    paymentConnectorResponse(payload){
            if(!payload) return
            let response
            if(payload.status == 200){
                    response = {
                    data: {
                      msg: {
                        id: this.$options.name,
                        text: payload.msg,
                      },
                      payment: payload.payment
                    }
                  };
                  EventBus.$emit('lease_edited');
                  EventBus.$emit('refresh_lease_tenants');
                }else{
                  response = {
                    error: payload.msg,
                  };
                 
                }
                EventBus.$emit('enable_reader_dropdown');
                this.enablePayment(response);
          },
    getErrors(value) {
      if(value === false) {
        this.processPayment();
        this.setCheckErrors({ value: null });
      }
    }
  }
}

</script>

<style scoped lang="scss">

  .intent-section{
    padding: 20px 50px 75px 50px;
  }

  .intent-section .new-tenant-walkthrough-footer{
    padding: 3px 0 3px 30px;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    // z-index: 100;
    left: 0;
    right: 0;
  }
  
  .intent-item-header {
    background-color: #FFFFFF;
    height: 56px;
    border: 1px solid #E7E7E8;
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
    font-size: 15px;
    font-weight: 500;
    padding-top: 4px;
    margin: 0;
  }

  .intent-item-body {
    background-color: #FFFFFF;
    height: 64px;
    border: 1px solid #E7E7E8;
    border-width: 0px 1px 1px 1px;
    font-size: 15px;
    font-weight: 500;
    margin: 0;
  }
 .navigation-panel-container{
    background: #F9FAFB;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: stretch;
    height: 100%;
    position: relative;
    overflow: hidden;
  }


  .panel-content {
    height: 100%;
    overflow: hidden;
    padding-bottom: 63px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
  }

  .user-info,
  .nav-drawer-header {
    background: white;
    flex: 0 1;
  }

  .navigation-panel-container .v-expansion-panel{
    margin-bottom: 10px;
    margin-top: 0;
    border: 1px solid #DFE3E8;
  }

  .info-panel{
    flex: 1 0;
    background-color: #F9FAFB;
  }
  .walkthrough-body{
    flex: 1;
  }
  .section-header{
    background: white;
  }

  .user-info{
    padding-left: 50px;
    padding-right: 5px;
    background-color: white;
  }


  .v-expansion-panel-content .form-label{
    background: #F4F6F8;
    padding: 20px;

  }

  .v-expansion-panel-content .form-label-active{
    background: #EFFAFA;
    padding: 20px;

  }

  .v-expansion-panel-content .form-label-active h3{
      color: #00848E;
  }

  .invoice_display{
    padding:  10px 0px;
    font-size: 14px;
  }

  .invoice-line-row{
    margin-bottom: 0;
    padding: 0px 20px;
  }
  .invoice-line-row .col {
    padding: 0;
  }

  .invoice-total-row >.col{
    padding: 10px;
  }

  .invoice-row .caption{
    color: #637381
  }

  .invoice-row > .col{
    padding-bottom: 0;
  }

  .invoice-line-row.total{
    border-top: 1px solid #C4CDD5;
    margin-top: 20px;
  }
  .invoice-line-row.total > .col{
    padding-top: 15px;
  }
  .payment-data{
    background: white;
    border: 1px solid #DFE3E8;
    margin-bottom: 10px;
    border-radius: 0;
  }

  .payment-data-header .v-input--checkbox,
  .form-label .v-input--checkbox{
    margin-top: 0;
    padding-top: 0;
  }

  .payment-data-header{
    border-bottom: 1px solid #DFE3E8;
    padding: 15px 20px;
    margin-bottom: 0;
  }

  .auto-debit-placeholder {
    border: 2px dashed #C4CDD5;
    border-radius: 4px;
    padding: 15px;
  }
  .new-tenant-form-data{
    border: 1px solid #DFE3E8;
  }
</style>

