<template>
  <div>
    <v-expansion-panels v-model="panel" multiple>
      <hb-expansion-panel :topMargin="false">
        <template v-slot:title>Terms &amp; Conditions</template>
        <template v-slot:content>
          <div class="pa-4">
            <HbCheckbox
              v-model="form.general.terms"
              v-validate.disable="'required'"
              data-vv-name="terms"
              data-vv-as="Terms and Conditions"
              data-vv-scope="agreements_info"
              @click="handleCheckBox"
            >
              <template v-slot:label>
                <div>
                  I agree to allow Tenant Inc. to use the information provided
                  to process my application for a merchant account. I also
                  accept the
                  <strong>
                    <hb-link
                      target="_blank"
                      href="https://www.propay.com/en-US/Legal/ProFac-Sub-merchant-Terms-and-Conditions"
                      >Propay
                    </hb-link>
                  </strong>
                  and
                  <strong>
                    <hb-link
                      target="_blank"
                      href="https://www.propay.com/en-US/Legal/Heartland-Propay-TC"
                      >Heartland-Propay
                    </hb-link>
                  </strong>
                  Terms &amp; Conditions.
                </div>
              </template>
            </HbCheckbox>
          </div>
        </template>
      </hb-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import api from '../../../../assets/api';
import moment from 'moment';

export default {
  name: "Agreements",
  props: {
    form: {
      type: Object,
      default: () => {},
    },
    property_id: {
      type: String
    }
  },
  data() {
    return {
      panel: [0],
      property: {},
    };
  },

  methods: {
    async handleCheckBox() {
      if(this.form.general.terms) {
          let r = await api.get(this, api.PROPERTIES + this.property_id);
          this.property = r.property;
        await this.calculateTime(this.property.utc_offset);
      }
    },

    async calculateTime(utc_offset) {
      console.log('utc: ', utc_offset);
      this.form.general.currentTimeStamp = moment().utc().format('YYYY-MM-DD HH:mm:ss');
      console.log('currentTimeStamp: ', this.form.general.currentTimeStamp);
    }
  }
};
</script>

<style lang="scss" scoped></style>
