<template>
    <div>
      <div class="tab-container">
        <v-tabs
            v-show="!this.unit_id"
            class="hb-text-light"
            color="#101318"
        >
            <v-tabs-slider color="#00848E"></v-tabs-slider>
<!--            <v-tab @click="active_tab = 'insurance'">Insurance</v-tab>-->
            <v-tab
                @click="active_tab = 'merchandise'"
                class="text-capitalize"
                :ripple="false"
            >
                Merchandise
            </v-tab>
            <v-tab 
                @click="active_tab = 'fees'"
                
                class="text-capitalize"
                :ripple="false"
            >
                Fees
            </v-tab>
        </v-tabs>
      </div>


        <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

        <div class="services-section scrollable-content">

<!--            <insurance v-if="active_tab === 'insurance'"></insurance>-->
            <merchandise
              v-if="active_tab === 'merchandise'"
              :property_id="property.id"
              :merchandise="merchandise"
              :services.sync="services">
            </merchandise>
            <fees
              v-if="active_tab === 'fees'"
              :property_id="property.id"
              :fees="fees"
              :lease_id="lease_id"
              :services.sync="services"
            ></fees>

        </div>


        <div class="new-tenant-walkthrough-footer">
            <hb-bottom-action-bar
                @close="$emit('close')"
                :top-border="false"
            >
                <template v-slot:left-actions>
                    <help-juice />
                </template>
                <template v-slot:right-actions>
                    <hb-btn color="primary" :disabled="isLoading($options.name) || !canProgress" @click="productsSaved">Take Payment</hb-btn>
                </template>
            </hb-bottom-action-bar>
        </div>
    </div>

</template>

<script type="text/babel">
    import api from '../../../assets/api.js';
    import Status from './../Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment';

    import Insurance from './Insurance';
    import Merchandise from './Merchandise';
    import Fees from './Fees';
    import { mapGetters, mapActions, mapMutations} from 'vuex'
    export default {
        name: 'ServicesSelection',
        components: {
            Status,
            Insurance,
            Merchandise,
            Fees
        },
        data: () => ({
            active_tab: 'merchandise',
            panel: 0,
            confirm: false,
            show_security_deposit: false,
            promotions:[],
            unit_id: '',
            products: [],
        }),
        props: ['property', 'services', 'lease_id'],

        created(){
          this.fetchProducts();
        },
        computed:{
            ...mapGetters({
                activeDrawerComponent: 'navigationStore/activeDrawerComponent'
            }),
            canProgress(){
                for(let s in this.services){
                  if(this.services[s] && this.services[s].qty && this.services[s].price  ) return true;
                }
                return false;
            },
            merchandise(){
                return this.products.filter(p => p.default_type === 'product').map(p => {
                    p.qty = this.services[p.id]?.qty ? this.services[p.id].qty : '';
                    p.price = this.services[p.id]?.price ? this.services[p.id].price : p.price;
                    return p;
                })
            },
            fees(){
                return this.products.filter(p => p.default_type === 'late')
            }
        },
        methods:{
            ...mapMutations({
                setActiveDrawerComponent:'navigationStore/SET_ACTIVE_DRAWER_COMPONENT',
            }),
            async productsSaved(){
                this.$emit('setServices', this.services);
            },
            async fetchProducts(){
                let r = await api.get(this, api.PROPERTIES + this.property.id + '/products');
                this.products = r.products;
            },
        },
        watch: {
            services() {
                this.setActiveDrawerComponent({ ...this.activeDrawerComponent, services: this.services});
            }
        },
    }
</script>

<style scoped>
    .tab-container{
      padding: 0 24px;
      background: none;
      border-bottom: 1px solid #E1E6EA
    }
    .new-tenant-walkthrough .v-tabs,
    .new-tenant-walkthrough .v-tabs .v-tabs-bar{
      background: #F9FAFB;
    }
    .new-tenant-walkthrough-footer{
        padding: 3px 0 3px 30px;
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 0px 24px 100px 24px;
    }

    .unit-confirm-icon .v-list-item__icon {
        text-align: center;
        background: white;
        padding: 25px;

    }

    .v-expansion-panel-content .form-label{
        background: #F4F6F8;
        padding: 20px;
        font-weight: bold;
    }
    .row {
        margin-bottom: 10px;
    }

</style>
