<template>
  <div class="new-tenant-walkthrough">

      <div class="new-tenant-walkthrough-container">
        <hb-blade-header
          title="Collection Center"
          title-icon="mdi-cash-usd-outline"
          @back="$emit('close')"
          @close="$emit('close')"
        >
          <template v-slot:left v-if="$vuetify.breakpoint.smAndUp">
            <v-autocomplete
              class="ml-1"
              v-if="!$props.contact"
              rounded
              hide-details
              v-model="active_lease"
              :items="tenantList"
              item-value="temp_id"
              item-text="Contact.first"
              outlined
              dense
              single-line
              return-object
              background-color="white"
              label="Select Tenant"
              @change="onTenantChange"
              @focus="onFocus"
              ref="autoCompleteId"
            >
              <template v-slot:no-data>
                <v-list-item v-show="tenantList && tenantList.length">
                  <v-list-item-title>No Tenants found</v-list-item-title>
                </v-list-item>
              </template>

              <template v-slot:selection="data">
                <v-list-item class="px-0" v-if="!autoCompleteFocus">
                  <v-list-item-content class="py-0">
                    <v-list-item-title>
                      <strong class="hb-text-night">{{data.item.Contact.first}} {{data.item.Contact.last}}</strong>
                      <span class="float-right" style="color: #FB4C4C;">{{data.item.total_due | formatMoney}} </span>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>

              <template v-slot:item="data" >
                <v-row>
                  <v-col>
                    <strong>{{data.item.Contact.first}} {{data.item.Contact.last}}</strong><br />
                  </v-col>
                  <v-col class="text-right">
                    <span class="due_amount">{{data.item.total_due}}</span>
                  </v-col>
                </v-row>
              </template>
            </v-autocomplete>
          </template>
          <template v-slot:right> 
            <hb-btn
              v-if="active_lease"
              class="ml-1"
              icon
              tooltip="Communication"
              @click="summary_panel = summary_panel === 'chat' ? null : 'chat'"
              :active="summary_panel === 'chat'"
              hover-background-color="#CAEAEA"
            >
              mdi-forum
            </hb-btn>
          </template>
        </hb-blade-header>

        <div class="new-tenant-walkthrough-content-section">
          <div class="onboarding-container">
              <div class="walkthrough-body">
                  <div class="px-6 mt-2">

                    <v-autocomplete
                      class="my-3"
                      v-if="!$props.contact && $vuetify.breakpoint.xs"
                      rounded
                      hide-details
                      v-model="active_lease"
                      :items="tenantList"
                      item-value="temp_id"
                      item-text="Contact.first"
                      outlined
                      dense
                      single-line
                      return-object
                      background-color="white"
                      label="Select Tenant"
                      @change="onTenantChange"
                      @focus="onFocus"
                      ref="autoCompleteId"
                    >
                      <template v-slot:no-data>
                        <v-list-item v-show="tenantList && tenantList.length">
                          <v-list-item-title>No Tenants found</v-list-item-title>
                        </v-list-item>
                      </template>

                      <template v-slot:selection="data">
                        <v-list-item class="px-0" v-if="!autoCompleteFocus">
                          <v-list-item-content class="py-0">
                            <v-list-item-title>
                              <strong class="hb-text-night">{{data.item.Contact.first}} {{data.item.Contact.last}}</strong>
                              <span class="float-right" style="color: #FB4C4C;">{{data.item.total_due | formatMoney}} </span>
                            </v-list-item-title>
                          </v-list-item-content>
                        </v-list-item>
                      </template>

                      <template v-slot:item="data" >
                        <v-row>
                          <v-col>
                            <strong>{{data.item.Contact.first}} {{data.item.Contact.last}}</strong><br />
                          </v-col>
                          <v-col class="text-right">
                            <span class="due_amount">{{data.item.total_due}}</span>
                          </v-col>
                        </v-row>
                      </template>
                    </v-autocomplete>

                    <!-- <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status> -->
                    <status @resetStatus="successClear($options.name)" v-if="successHas($options.name)" :message="successGet($options.name)" status="success"></status>

                    <v-expansion-panels v-model="panel" :disabled="!active_lease" >
                      <hb-expansion-panel
                        key="0"
                        class="mb-2"
                        content-padding
                        :left-cols="11"
                        :right-cols="1"
                      >
                        <template v-slot:title>
                          Log Phone Call<span class="ml-4">Mobile {{ activeLeasePhone | formatPhone}}</span>
                        </template>

                        <template v-slot:content>
                          <v-row class="call-info mx-0">
                            <v-col cols="11">
                              <hb-icon color="#101318">mdi-phone</hb-icon>
                              <span class="font-weight-medium ml-2 mb-2">Collection Call:</span>
                              <span class="font-weight-regular ml-2 mb-2">Call tenants regarding the account balance</span>
                              <br>
                              <ol class="text-body-2 font-weight-medium pl-4">
                                <li class="pt-2 pb-1">
                                  <span>Identify the delinquent tenant</span>
                                  <span class="font-weight-regular ml-1">before discussing the past due rent</span>
                                </li>
                                <li>
                                  <span>Identify who you are,</span>
                                  <span class="font-weight-regular ml-1">the</span>
                                  <span class="ml-1">purpose of your call,</span>
                                  <span class="font-weight-regular ml-1">and</span>
                                  <span class="ml-1">be direct</span>
                                  <span class="font-weight-regular ml-1">in your communication with the tenant</span>
                                </li>
                              </ol>
                            </v-col>
                            <v-col cols="1">
                              <a class="float-right" @click="panel = null" >Hide</a>
                            </v-col>
                          </v-row>
                          <v-row no-gutters class="mt-5">
                            <v-col cols="6" class="ma-0 py-0 pr-2 pl-0">
                              <hb-select
                                :items="answerList"
                                v-model="tenantAnswer"
                                placeholder="Did the tenant answer?"
                                name="answer"
                                v-validate="'required'"
                                data-vv-as="Call Answer"
                                :error="errors.has('answer')"
                              ></hb-select>
                            </v-col>
                            <v-col cols="6" class="ma-0 py-0 pl-2 pr-0">
                              <hb-textarea
                                v-model="noteContent"
                                name="note_content"
                                v-validate="'required'"
                                data-vv-as="Notes"
                                :error="errors.has('note_content')"
                                placeholder="Enter Notes"
                                rows="1"
                              ></hb-textarea>
                            </v-col>
                          </v-row>
                        </template>
                        <template v-slot:footer>
                          <hb-bottom-action-bar cancel-off>
                            <template v-slot:right-actions>
                              <hb-btn color="secondary" @click="setReminder">Create Task</hb-btn>
                              <hb-btn @click="sendNote" color="primary"> Save Call Record </hb-btn>
                            </template>
                          </hb-bottom-action-bar>
                        </template>
                      </hb-expansion-panel>
                    </v-expansion-panels>
                    <v-row class="ma-0">
                      <h2 class="title mt-4 mb-2">Lease Summary</h2>
                    </v-row>
                    <v-row v-if="active_lease && lease.Unit && selectedTenant">
                      <v-col cols="6">

<!-- STOP -->
<!-- please do not copy the <HbInfoCard /> code below and do not use it in other places, it is used wrong/deprecated (it is wrongly passing in an objects) -->
<!-- please see aviary design system documentation for info card component for proper usage via props and use the code there for reference -->
                        <HbInfoCard
                          v-if="lease.Unit"
                          class="mb-4"
                          :unit="lease.Unit"
                          @click="viewEntity('unit', lease.Unit.id)"
                        />
                        <HbInfoCard
                          v-if="selectedTenant.Contact" 
                          :contact="selectedTenant.Contact"
                          :contact-is-business="selectedTenant.rent_as_business"
                          @click="viewEntity('contact', selectedTenant.Contact.id)"
                        />
<!-- STOP -->
<!-- please do not copy the <HbInfoCard /> code above and do not use it in other places, it is used wrong/deprecated (it is wrongly passing in an objects) -->
<!-- please see aviary design system documentation for info card component for proper usage via props and use the code there for reference -->

                      </v-col>
                      <v-col cols="6">
                      <div v-if="active_lease" class="ls-bottom-content px-4 py-3">
                        <v-row class="summary-heading">Account Balance:</v-row>
                        <v-row class="pb-3 due_amount">{{accountBalance | formatMoney}}</v-row>
                        <v-row class="summary-heading">Pay Through Date:</v-row>
                        <v-row class="pb-3 hb-text-night">{{leaseMatrices.paid_through_date | formatLocalShortDate}}</v-row>
                        <v-row class="summary-heading">Move-In Date:</v-row>
                        <v-row class="pb-3 hb-text-night">{{lease.start_date | formatLocalShortDate}}</v-row>
                        <v-row class="summary-heading">Tenant Lifetime Value:</v-row>
                        <v-row class="hb-text-night">{{leaseMatrices.lifetime_payments | formatMoney}}</v-row>

                      </div>

                      </v-col>
                    </v-row>
                  </div>
              </div>
          </div>
          <div class="new-tenant-info-panel align-stretch" v-if="summary_panel && selectedTenant">
            <chat-window dense chatWindowHeight="x-large"
             v-show="summary_panel === 'chat'" 
            @hide="summary_panel = ''" 
            :InteractionObj="selectedTenant.Contact" 
    
            >
          </chat-window>
          </div>
        </div>
      </div>
      <div class="new-tenant-walkthrough-footer">
        <hb-bottom-action-bar
          @close="$emit('close')"
          :top-border="false"
        >
          <template v-slot:left-actions>
            <help-juice />
            <hb-btn
              icon
              tooltip="Create Task"
              @click="createTask"
              :active="reminderFlag"
            >
              mdi-calendar-today
            </hb-btn>
          </template>
          <template v-slot:right-actions>
            <hb-btn color="secondary" :disabled="!active_lease" @click="completeTask">Complete Task</hb-btn>
            <hb-btn color="primary" :disabled="!active_lease" @click="newPayment">Take a Payment</hb-btn>
          </template>
        </hb-bottom-action-bar>
      </div>
      <todo v-model="reminderFlag" v-if="showTodo && reminderFlag" :title_text="title_text" :contact_id="isReminder ? contact_id : ''" :lease_id="isReminder ? lease_id : ''" :event_type="isReminder ? 'collection_calls' : ''" :btn_text="btn_text"></todo>
  </div>

</template>

<script>

import ChatWindow from '../../communication_center/ChatWindow.vue'
import Todo  from '../Todo.vue'
import api from '../../../assets/api.js'
import { EventBus } from '../../../EventBus.js'
import Status from '../../includes/Messages.vue'
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'DelinquencyCenter',
  props: ['contact'],
  data() {
    return {
      step: 1,
      summary_panel: '',
      panel: null,
      answerList: [
        { text: 'Call answered', value: 'Call answered' },
        { text: 'Call answered but tenant not available', value: 'Call answered but tenant not available' },
        { text: 'No answer left message', value: 'No answer left message' },
        { text: 'No answer left message on voicemail', value: 'No answer left message on voicemail' },
        { text: 'No answer voicemail full', value: 'No answer voicemail full' },
        { text: 'No answer', value: 'No answer' },
        { text: 'Number no longer in service', value: 'Number no longer in service' },
        { text: 'Number is temporarily disconnected', value: 'Number is temporarily disconnected' },
        { text: 'Number belongs to someone else', value: 'Number belongs to someone else' }
      ],
      note: '',
      lease: {},
      access: {},
      leaseMatrices: {},
      lease_id: '',
      contact_id: '',
      noteError: false,
      noteContent: '',
      tenantAnswer: null,
      reminderFlag: false,
      tenantList: [],
      active_lease: null,
      autoCompleteFocus: false,
      title_text: '',
      btn_text: '',
      showTodo: false,
      isReminder: false,
      currentTask:{},
    }
  },
  components: {
    ChatWindow,
    Todo,
    Status
  },
  watch: {
    active_lease() {
      if (this.active_lease) {
        this.autoCompleteFocus = false
      }
    }
  },
  computed: {
    ...mapGetters({
          eventTypes: 'taskCenterStore/eventTypes'
    }),
    selectedTenant() {
      if(this.lease.Tenants){
        let tenant = this.lease.Tenants.find(x => x.contact_id == this.contact_id);
        return tenant;
      }
    },
    selectedPhone() {
      if(this.selectedTenant){
        let phone = this.selectedTenant.Contact.Phones.length ? this.selectedTenant.Contact.Phones[0].phone : '';
        return phone;
      }
    },
    accessCode() {
      return this.access.pin ? this.access.pin : "Not Available";
    },
    accountBalance() {
      if(this.lease.OpenInvoices.length){
        var sum = 0;
        var i;
        for (i = 0; i < this.lease.OpenInvoices.length; i++) { 
          sum += this.lease.OpenInvoices[i].balance;
        }
        return sum;
      } else {
        return 0;
      }
    },
    activeLeasePhone () {
      if (!this.active_lease) return
      return this.active_lease.Contact.Phones.length ? this.active_lease.Contact.Phones[0].phone : ''
    }
  },
  async created () {
  
    await this.getActiveLeaseList()
   
  },
  methods: {
    ...mapActions({
        markTask:  'taskCenterStore/markTask', 
        setTaskCounts: 'taskCenterStore/setTaskCounts', 
    }), 
    onFocus(e){
      this.autoCompleteFocus = true
    },
    setReminder() {
      this.isReminder = true;
      this.showTodo = true;
      this.reminderFlag = true;
      this.title_text = 'Create Task';
      this.btn_text = 'Create';
    },
    createTask() {
      this.isReminder = false;
      this.showTodo = true;
      this.reminderFlag = true
      this.title_text = 'Create Task';
      this.btn_text = 'Create';
    },
    async getLeaseData() {
      let r = await api.get(this, api.LEASES + this.lease_id);
      this.lease = r.lease;
      this.contact_id = r.lease.Tenants.length > 0 ? r.lease.Tenants[0].contact_id : ''
    },
    async getAccessData() {
      let r = await api.get(this, api.CONTACTS + this.contact_id + '/access').then(r => {
          this.access = r.access.length ? r.access[0] : '';
      })
    },
    async getLeaseMatrices() {
      let r = await api.get(this, api.LEASES + this.lease_id + '/metrics')
      this.leaseMatrices = r.metrics;
    },
    sendNote () {
      this.validate(this).then(status => {
        if (!status) {
          return
        }
        var data = { 
          content: this.noteContent,
          method: 'phone',
          created: new Date(),
          pinned: 0,
          context: 'delinquencyCenter',
          tenant_answer: this.tenantAnswer,
          ref_object_type: 'lease', 
          ref_object_id: this.lease.id,
          space:this.lease.Unit.number
        }
      

        api.post(this, api.CONTACTS + this.contact_id + '/interaction', data).then(note =>{
            this.noteContent = ''
            this.tenantAnswer = null
            this.summary_panel = 'chat';  
            EventBus.$emit('communication::refresh', {contact_id: this.contact_id})
            this.successSet(this.$options.name, "Call record saved successfully.");
        })

      })
    },
    async getActiveLeaseList() {
      if(this.$props.contact){
        this.active_lease = {
          lease_id: this.$props.contact.lease_id,
          contact_id: this.$props.contact.id,
          Contact: this.$props.contact
        }     
        this.currentTask = this.$props.contact.task ? this.$props.contact.task : this.$props.contact;
      } else {
        let response  = await api.post(this, api.TENANTS + 'delinquent');
        this.tenantList = response.tenants.map((x,i) => {
            return {
              ...x,
              temp_id: i
            }
          })
          
          this.active_lease = this.tenantList.length > 0 ? this.tenantList[0] : null 
      }

      this.summary_panel = 'chat'
      this.onTenantChange()
    },
    onTenantChange () {
      this.lease_id = this.active_lease.lease_id
      this.contact_id = this.active_lease.contact_id
      this.getLeaseData();
      this.getContactData()
     console.log("on tenant change")
      this.getLeaseMatrices();
      this.getAccessData();
      if (this.$refs.autoCompleteId) {
        this.$refs.autoCompleteId.blur()
      }
      this.panel = 0;
    },
    nextCollection () {
      if (!this.active_lease) {
        this.active_lease = this.tenantList.length ? this.tenantList[0] : null
      } else {
        this.successClear(this.$options.name)
        let index = this.active_lease.temp_id + 1
        
        if (index === this.tenantList.length) {
          this.active_lease = null
        } else {
          this.active_lease =  this.tenantList[index]
          this.onTenantChange()
          this.panel = 0
        }

      }
    },
    newPayment(){
      if(!this.active_lease) return
      let data = {
          property_id: this.lease.Unit.property_id,
          contact_id: this.contact_id
      }
      EventBus.$emit('make_payment', data);
    },
    closeTodo(){
      this.reminderFlag = false;
      setTimeout(() => {
        this.showTodo = false;
      }, 220);
    },
    async completeTask(){   
      let todo = this.currentTask; 
      if (todo.completed) return; 
      let r = await api.put(this, api.TODOS + 'dismiss', { todos: [todo] }, 'none'); 
      this.markTask({task_id: todo.id, complete: true}); 
      this.setTaskCounts({ role_ids: this.rolesIDs });
      this.currentTask =  {};
      this.$emit('close');
      EventBus.$emit('reset_profile_tasks');
    },
    viewEntity(entity, entity_id){
      let params = {};
      params = {
        component: entity,
        props: {}
      };
      params.props[entity + '_id'] = entity_id;
      this.$store.dispatch('navigationStore/openSlideOut', params);
    },
  }
}
</script>

<style scoped>
  .v-dialog {
   overflow-y: none; 
  }
  .due_amount {
    color: #FB4C4C;
  }
  .new-tenant-walkthrough-footer{
    padding: 3px 0 3px 0;
    padding: 0 !important;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
  }
  span{
    color: #101318;
  }
  .phone-log-header {
    background: linear-gradient(180deg, #FFFFFF 0%, #F9FAFB 100%);
    box-shadow: inset 0px -1px 0px rgba(11, 18, 29, 0.1);
  }
  .call-info {
    background: #E0F5F5;
    border: 1px solid rgba(0, 132, 142, 0.2);
    box-sizing: border-box;
    border-radius: 4px;
  }

  .new-tenant-walkthrough-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      height: 100%;
      flex: 1 1;
      position: relative;
      overflow: hidden;
  }
  .new-tenant-walkthrough-content-section{
      align-items:stretch;
      display: flex;
      overflow: hidden;
      padding-bottom: 50px;
      height: 100%;
  }
  .new-tenant-walkthrough{
      background: #F9FAFB;
      width: 100%;
      overflow: hidden;
  }
  .v-stepper__header {
      max-width: 525px;
      border: none;
      box-shadow:none;
      margin: 0 auto;
  }
  .new-tenant-stepper{
      border-bottom: 1px solid #E1E6EA;
      text-align: center;
      box-shadow:none;
      background: white;
  }
  .new-tenant-info-panel{
    flex: 0 0 420px;
    max-width: 420px;
    height: 75vh;
    overflow:auto;
    background-color: #F9FAFB;
    border-left: 1px solid #E1E6EA;
  }
  .walkthrough-body{
      /* flex: 1; */
      overflow-y: scroll; 
      height: 75vh;
  }

  .nav-drawer-header{
      flex: 0 1 auto;
      min-height:72px;
      background: #FFFFFF;
  }
  .onboarding-container{
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: stretch;
      flex: 1;
  }
  .summary-heading {
      color: #637381;
  }

  @media (min-height: 700px) {
    .walkthrough-body{
      height: 75vh;
    }
    .new-tenant-info-panel{
      height: 75vh;
    }
  }

  @media (min-height: 800px) {
    .walkthrough-body {
      height: 79vh;
    }
    .new-tenant-info-panel{
      height: 79vh;
    }
  }

  @media (min-height: 900px) {
    .walkthrough-body {
      height: 81vh;
    }
    .new-tenant-info-panel{
      height: 81vh;
    }
  }

  @media (min-height: 1025px) {
    .walkthrough-body{
      height: 83vh;
    }
    .new-tenant-info-panel{
      height: 83vh;
    }
  }

</style>