<template>
    <div>

        <div v-if="loading">
          <v-row justify="center" align="center">
            <v-col class="text-center ma-12">
              <v-progress-circular width="2" size="32" indeterminate color="primary" ></v-progress-circular>
            </v-col>
          </v-row>
        </div>

        <div class="services-section scrollable-content" v-else>
            <v-container class="ma-0 pa-0">
                <v-row no-gutters>
                    <v-col md="4" class="ma-0 pa-0 mt-n1">
                        <span class="hb-font-header-2-medium">Finalize Lease</span>
                    </v-col>
                </v-row>
            </v-container>

            <status @resetStatus="errorClear($options.name)" v-if="errorHas($options.name)" :message="errorGet($options.name)" status="error"></status>

            <v-expansion-panels v-model="panels" multiple>

              <hb-expansion-panel v-if="signature_items.length" content-padding>
                <template v-slot:title>
                  Document Signing
                </template>

                <template v-slot:content>
                  <v-row no-gutters>
                    <v-col class="pa-0 ma-0">
                      <div class="mb-6">Please have the Tenant sign their agreements to finalize the rental.</div>
                      <v-container class="px-0 py-0">
                        <v-row no-gutters v-for="item in signature_items" :key="item.id"  >
                          <v-col class="py-0 px-1 col mb-6" v-if="status_rank(item) === 1 || status_rank(item) === 2">
                            <v-progress-circular
                              indeterminate
                              class="pa-0 ma-0 mr-2"
                              size="16"
                              width="2"
                              color="primary"
                            ></v-progress-circular>
                            {{ item.name }}
                          </v-col >

                          <v-col class="ma-0 pa-0 selection-controls-fix" v-else>
                            <v-checkbox class="ma-0 pa-0" v-if="status_rank(item) <= 5 && !item.completed" :label="item.name" :value="item.id" v-model="docs_to_sign"></v-checkbox>
                            <v-checkbox class="ma-0 pa-0" v-else disabled :label="item.name" v-model="item.completed"></v-checkbox>
                          </v-col >

                          <v-col class="text-right ma-0 pa-0" :class="{'hb-text-accept': item.status === 'complete' || item.status === 'finished'}">
                            <hb-icon v-if="item.status === 'complete' || item.status === 'finished'" color="#02AD0F" style="position:relative;top:-2px;">mdi-check</hb-icon>
                            <span class="ml-1 mr-2">{{status_message(item)}}</span>
                          </v-col>

                          <v-col class="text-right flex-shrink ma-0 pa-0 mt-n2 mb-0" style="flex: 0 1" >
                            <hb-menu options>
                              <v-list>
                                <v-list-item v-if="(item.completed  && item.Upload.src) || status_rank(item) > 4 || (status_rank(item) >= 3 && item.Upload.src)" @click="" :ripple="false">
                                  <v-list-item-title>
                                    <a :href="item.Upload.src" target="_blank" style="text-decoration: none;">Download/Print</a>
                                  </v-list-item-title>
                                </v-list-item>
                                <v-list-item v-show="status_rank(item) < 5 && !item.completed" @click="upload(item)" :ripple="false">
                                  <v-list-item-title>Upload Document</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="remove(item)" :ripple="false">
                                  <v-list-item-title>Discard and Start Over</v-list-item-title>
                                </v-list-item>

                                <v-list-item @click="fetchData" :ripple="false">
                                  <v-list-item-title>Refresh</v-list-item-title>
                                </v-list-item>

<!--                                <v-list-item v-if="status_rank(item) >= 4" @click="email_copy(item)" :ripple="false">-->
<!--                                  <v-list-item-title>Email Copy</v-list-item-title>-->
<!--                                </v-list-item>-->

                              </v-list>
                            </hb-menu>

                          </v-col>
                        </v-row>
                      </v-container>
                      <v-container class="px-0 py-0 mt-2">
                        <hb-btn :disabled="!ready_to_sign" @click="sign_documents" color="primary" class="mr-3">Sign on This Device</hb-btn>
                        <hb-btn :disabled="!ready_to_sign" color="secondary" @click="openSendDocument" class="mr-3">Send for Signing</hb-btn>
                        <hb-btn :disabled="!ready_to_sign" color="secondary" @click="getSignLink" class="mr-3">Get Link</hb-btn>
                      </v-container>

                    </v-col>
                  </v-row>
                </template>
              </hb-expansion-panel>

              <hb-expansion-panel v-show="standard_items.length" content-padding>
                <template v-slot:title>
                  Move-In Checklist
                </template>

                <template v-slot:content>
                  <HbCheckbox
                    v-for="(item, i) in std_items"
                    :key="item.id"
                    @change="updateChecklist(item)"
                    v-model="item.completed"
                    :label="item.name"
                    :class="{'mt-3' : i > 0}"
                  />
                </template>
              </hb-expansion-panel>

              <!--
              <v-expansion-panel elevation="0" v-show="standard_items.length">
                <v-expansion-panel-header class="hb-default-font-size pt-3 pb-4 px-6">
                  <v-col cols="12" class="pa-0 ma-0">
                    <span class="text-h6">Move-In Checklist</span>
                  </v-col>
                </v-expansion-panel-header>
                <v-divider></v-divider>
                <v-expansion-panel-content class="px-0 py-4 ma-0 ma-0" elevation="0">
                  <v-row class="pa-0 ma-0">
                    <v-col class="py-0 px-6 ma-0">
                      <v-container class="px-0 py-0">
                        <v-row no-gutters v-for="item in std_items" :key="item.id"  >
                          <v-col class="pa-0">

                            <v-checkbox class="ma-0 pa-0" color="primary" @change="updateChecklist(item)" v-model="item.completed" :label="item.name"></v-checkbox>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
              -->





<!--              <v-expansion-panel elevation="0">-->
<!--                <v-expansion-panel-header class="hb-default-font-size pt-3 pb-4 px-6">-->
<!--                  <v-col cols="12" class="pa-0 ma-0">-->
<!--                    <span class="text-h6">Identity Verification</span>-->
<!--                  </v-col>-->
<!--                </v-expansion-panel-header>-->
<!--                <v-divider></v-divider>-->
<!--                <v-expansion-panel-content class="px-0 pb-4 ma-0" elevation="0">-->
<!--                  <v-row class="pa-0 ma-0">-->
<!--                    <v-col class="py-0 px-6 ma-0">-->
<!--                      <v-radio-group v-model="identity_verification">-->
<!--                        <v-radio class="py-1" label="Manual ID verification in-store or over the phone" value="manual"></v-radio>-->
<!--                        <v-radio class="py-1" label="Send ID verification to mobile device" value="omni_channel"></v-radio>-->
<!--                        <v-radio class="py-1" label="Perform ID Verification on this Device" value="device"></v-radio>-->
<!--                      </v-radio-group>-->

<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                  <v-row class="pa-0 ma-0" v-show="identity_verification && identity_verification !== 'manual' ">-->
<!--                    <v-col class="py-0 px-6 ma-0">-->
<!--                      <hb-btn color="primary">Verify Identity</hb-btn>-->
<!--                    </v-col>-->
<!--                  </v-row>-->
<!--                </v-expansion-panel-content>-->
<!--              </v-expansion-panel>-->


            </v-expansion-panels>
        </div>

      <sign
        @close="sign_process = false"
        @completed="closeSignProcess"
        :property_id="property.id"
        v-if="uploads_to_sign.length && sign_process"
        :uploads="uploads_to_sign"
        :signer_id="signer_id"
      ></sign>

      <v-dialog v-model="saved" width="500">
        <v-card>
          <v-card-title class="headline">Lease Saved</v-card-title>
          <v-card-text class="text-center">
            This lease has been saved successfully!
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="$emit('close', true)">
              Close Window
            </v-btn>
            <v-btn color="primary" @click="goToLease">Go To Lease</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>


      <v-dialog v-model="send_docs" width="500">
        <send-for-signature
          :signer="to_send"
          :lease_id="lease.id"
          @close="send_docs = false">
        </send-for-signature>
      </v-dialog>


      <hb-modal v-model="show_link" size="medium" title="Signing Link" content-padding close-instead-of-cancel show-help-link>
        <template v-slot:content>
          You can text or email this link to be able to sign the document:
          <br />
          <br />
          {{link.shortUrl}}
        </template>
      </hb-modal>


      <send-document
          v-model="email_doc_copy"
          v-if="email_doc_copy"
          :upload_id="file.upload_id"
          :lease_id="lease.id"
          @close="email_doc_copy = false"
        ></send-document>

      <upload-file
        v-model="upload_document"
        v-if="upload_document"
        type="file"
        model="leases"
        :foreign_id="lease.id"
        size="lg"
        slot="body"
        @close="upload_document=false"
        @save="completeUpload">
      </upload-file>

      <div class="new-tenant-walkthrough-footer">

        <hb-bottom-action-bar
            @close="$emit('close')"
            :top-border="false"
        >
            <template v-slot:left-actions>
              <!-- <help-juice /> -->
            </template>
            <template v-slot:right-actions>

                <hb-btn
                    :disabled="isLoading($options.name) || !!incomplete_items.length"
                    :loading="finalizeInProgress"
                    @click="openInvocieGeneration"
                >
                    Finalize
                </hb-btn>

            </template>
        </hb-bottom-action-bar>

      </div>

      <hb-modal v-model="sendDocument" title="Send for Signing" show-help-link>
        <template v-slot:content>

          <!--

          <status class="mr-5 mt-2" @resetStatus="errorClear('sendSignLink')" v-if="errorHas('sendSignLink')" :message="errorGet('sendSignLink')" status="error"></status>
          <status class="mr-5 mt-2" @resetStatus="successClear('sendSignLink')" v-if="successHas('sendSignLink')" :message="successGet('sendSignLink')" status="success"></status>

          -->

          <hb-form label="Number of Documents">
            {{send_doc_data.docs.length}}
          </hb-form>
          
          <hb-form label="Message">
            <HbTextarea v-model="send_doc_data.message" />
          </hb-form>

          <hb-form label="Method" :divider="false" required>
            <div class="pb-12">
              <hb-radio-group v-model="send_doc_data.method" v-validate="'required'" data-vv-scope="send" data-vv-as="Method">
                <hb-radio
                  v-if="send_doc_data.contact.email"
                  key="Email"
                  :label="'Email ' + send_doc_data.contact.email"
                  value="email"
                ></hb-radio>
                <hb-radio
                  v-for="(phone, i) in send_doc_data.contact.Phones"
                  :key="i"
                  :label="'Text ' + $options.filters.formatPhone(phone.phone)"
                  :value="phone.id"
                ></hb-radio>
              </hb-radio-group>
            </div>
          </hb-form>
        </template>
        <template v-slot:actions>
          <hb-btn color="primary" :disabled="isLoading('send_sign')" :loading="isLoading('send_sign')" @click="sendSignLink">Send Link</hb-btn>
        </template>
      </hb-modal>


        <hb-modal 
        size="medium" 
        title="Invoice Generation" 
        v-model="invoiceGeneration"
        v-if="invoiceGeneration"
        show-help-link
        confirmation> 
          <template v-slot:content> 
            <div class="py-4 px-6"> 
              The move-in date for this rental is outside the invoice threshold period ({{invoice_send_day}} Days). The invoice for the next billing period will be generated after clicking continue. This invoice will be visible in the tenant’s transaction history.  
            </div> 
            </template> 
            <template v-slot:actions>
                <hb-btn class="mr-2" color="primary" @click="finalize">Contine</hb-btn>
            </template>
        </hb-modal>


    </div>

</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import moment from 'moment';
    import Sign from '../../includes/SignDocuments.vue';
    import SendForSignature from '../../includes/SendForEsign.vue';
    import SendDocument from '../../includes/SendDocument.vue';
    import { mapGetters, mapActions } from 'vuex';
    import { EventBus } from '../../../EventBus.js';
    import UploadFile from '../../assets/UploadFile.vue';
    import { notificationMixin } from  '@/mixins/notificationMixin.js';
    export default {
        name: "Checklist",
        mixins: [notificationMixin],
        data() {
            return {
                std_items: [],
                standard_item_checkboxes: [],
                initial: false,
                upload_document : false,
                send_docs : false,
                email_doc_copy : false,
                file: {},
                all_documents: 'all',
                to_send_sms: false,
                documents_loaded: false,
                interval: '',
                identity_verification: 'manual',
                docs_to_sign: [],
                sign_method: '',
                to_send: {},
                printLoading: false,
                documents_to_sign: [],
                sign_process: false,
                uploads_to_sign:[],
                signer_id: null,
                active_item: {},
                link:'',
                show_link:false,
                saved: false,
                // payment:{
                //     id: '',
                //     transaction_id: '',
                //     amount: '',
                // },
                panels: [0,1,2],
                selected_upload: {},
                statuses: [
                    'error',
                    'not_processed',
                    'loading',
                    'ready',
                    'loaded',
                    'initiated',
                    'complete',
                    'finished',
                ],
                sendDocument: false,
                send_doc_data: {
                    message: '',
                    method: '',
                    contact: {},
                    docs: []
                },
                invoiceGeneration: false,
                currentDate: '',
                invoiceGenerationDifference: '',
                threshold: false,
                finalizeInProgress: false
            }
        },
        components:{
            Status,
            Loader,
            Sign,
            SendForSignature,
            SendDocument,
            UploadFile
        },
        props: ['loading', 'accessCode'],
        async created (){
            this.$emit('update:loading', false)
            await this.fetchData();
            EventBus.$on('pandadoc_generation_update', this.updateSignature);

            this.docs_to_sign = this.signature_items.map(si => si.id);

            this.std_items = JSON.parse(JSON.stringify(this.standard_items));

            // EventBus.$on('document_signed', this.fetchData);
            // EventBus.$on('document_downloaded', this.markDownloaded);
            if(this.signature_items.length) {
              this.interval = setInterval(() => this.checkDocumentsUploaded(), 3000);
            }
            this.currentDate = moment().format('YYYY-MM-DD');
        },
        beforeDestroy(){
            EventBus.$off('pandadoc_generation_update', this.updateSignature);
            // EventBus.$off('document_signed', this.fetchData);
            // EventBus.$off('document_downloaded', this.markDownloaded);
            clearInterval(this.interval);
        },
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            },
            getEndOfPeriod(date){
                return moment(date).add(1, 'month').subtract(1, 'day');
            }
        },
        computed: {
            ...mapGetters({
                properties: 'propertiesStore/filtered',
                property: 'onBoardingStore/property',
                lease: 'onBoardingStore/lease',
                template: 'onBoardingStore/template',
                payment: 'onBoardingStore/payment',
                paymentMethod: 'onBoardingStore/paymentMethod',
                billed_months: 'onBoardingStore/billed_months',
                items: 'onBoardingStore/items',
                contact: 'onBoardingStore/contact',
            }),
            standard_items(){
                return this.items.filter(c => !c.document_id);
            },
            signature_items(){
                return this.items.filter(c => c.document_id);
            },
            documents(){
                return this.items.filter(c => c.document_id)
            },
            incomplete_items(){
                return this.items.filter(c => !c.completed);
            },

            ready_to_sign(){


                return !this.signature_items.filter(item => !item.completed && this.status_rank(item) < 3).length &&
                    this.signature_items.filter(item => !item.completed).length &&
                    this.docs_to_sign.filter(id => {
                        return !this.signature_items.find(item => item.id === id).completed;
                    }).length;
            },
            invoice_send_day() {
              let [key = {}] = Object.keys(this.template);
              let {invoiceSendDay = 0 } = this.template[key];
              return invoiceSendDay;
            }

        },
        methods: {
            ...mapActions({
              resetPropertiesInfo: 'propertiesStore/resetPropertiesInfo',
              fetchPropertyGroups: 'propertiesStore/fetchPropertyGroups'
            }),
            async checkDocumentsUploaded() {
              await this.fetchData(true);
              if(this.signature_items.length) {
                let document_loaded = this.signature_items.filter(si => (si.status != 'finished' && si.status != 'complete'));
                if(!document_loaded.length) {
                  clearInterval(this.interval);
                }
              }
            },
            openSendDocument(item){

                let docs = [];
                if(!this.docs_to_sign || !this.docs_to_sign.length) this.errorSet(this.$options.name, "Please Choose documents to sign.");
                if(!Array.isArray(this.docs_to_sign)){
                    this.docs_to_sign = [this.docs_to_sign];
                }
                for(let i = 0; i < this.docs_to_sign.length; i++){
                    let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
                    if(!doc.completed){
                        docs.push(doc);
                    }
                }

                if(!docs.length || !docs[0].Upload || !docs[0].Upload.signers || !docs[0].Upload.signers.length || docs[0].Upload.signers.Contact ){
                    return;
                }
                this.send_doc_data.docs = docs;
                this.send_doc_data.contact = this.contact;
                this.errorClear('sendSignLink')
                this.successClear('sendSignLink')

                if(this.contact.Phones && this.contact.Phones.length){
                    this.send_doc_data.method = this.contact.Phones[0].id;
                } else {
                    this.send_doc_data.method = 'email';
                }
                this.send_doc_data.message = "Please click the link to sign your documents";
                this.sendDocument = true;
            },

            async sendSignLink(){
              let send_status = await this.$validator.validateAll('send');

              if(!send_status){
                this.showMessageNotification({ type: 'error', description: 'There are errors in your form, correct them before continuing.', list: this.errors.items });
                return;
              }

              var data = {
                  docs: this.send_doc_data.docs,
                  contact_id: this.contact.id,
                  property_id: this.property.id,
                  method: this.send_doc_data.method,
                  message: this.send_doc_data.message
              };

              try {
                let response = await api.post(this, api.SEND_SIGN_LINK, data, "sendSignLink");
                
                if(response){
                  this.sendDocument = false;

                  if(this.send_doc_data.method === 'email'){
                    this.showMessageNotification({ type: 'success', description: 'An email with instructions has been sent to this customer.' });
                  } else {
                    this.showMessageNotification({ type: 'success', description: 'A text with instructions has been sent to this customer.' });
                  }
                }
              } catch(err) {
                this.showMessageNotification({ description: err });
              }

              /*
              let response = await api.post(this, api.SEND_SIGN_LINK, data, "sendSignLink");
              if(this.send_doc_data.method === 'email'){
                  this.successSet("sendSignLink", "An email with instructions has been sent to this customer.");
              } else {
                  this.successSet("sendSignLink", "A text with instructions has been sent to this customer.");
              }
              */
            },

            status_message(item){

                switch(item.status){
                    case 'error':
                        return "An error occured, please try again";
                        break;
                    case 'not_processed':
                    case 'loading':
                        return "loading";
                        break;
                    case 'ready':
                    case 'loaded':
                        return "Ready to sign";
                        break;
                    case 'initiated':
                        return "Signing Started";
                        break;
                    case 'complete':
                    case 'finished':
                        if(item.Upload.signers && item.Upload.signers.length && item.Upload.signers[0].signed){
                          return item.Upload.signers[0].Contact.first + ' ' + item.Upload.signers[0].Contact.last + ' | ' + this.$options.filters.formatLocalDateTime(item.Upload.signers[0].signed);
                        } else {
                          return  "Signing complete. Finalizing document";
                        }
                        break;
                    default:
                        break;
                }
            },

            async getSignLink(item){

                let docs = [];
                if(!this.docs_to_sign || !this.docs_to_sign.length) this.errorSet(this.$options.name, "Please Choose documents to sign.");

                if(!Array.isArray(this.docs_to_sign)){
                    this.docs_to_sign = [this.docs_to_sign];

                }
                for(let i = 0; i < this.docs_to_sign.length; i++){
                    let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
                    if(!doc.completed){
                      docs.push(doc);
                  }
                }

                if(!docs.length || !docs[0].Upload || !docs[0].Upload.signers || !docs[0].Upload.signers.length || docs[0].Upload.signers.Contact ){
                    return;
                }
                var data = {
                    docs: docs,
                    contact_id: docs[0].Upload.signers[0].Contact.id,
                    property_id: this.property.id
                };
                let response = await api.post(this, api.GET_SIGN_LINK, data);
                this.link = response.link;
                this.show_link = true;

            },

            updateSignature(payload){
                this.$store.commit('onBoardingStore/updateChecklistItem', payload);
            },

            status_rank(item){
                return this.statuses.indexOf(item.status);
            },

            item_url(item){
                let slug = this.$options.filters.slugify(item.Upload.name);
                return process.env.VUE_APP_API_PROTOCOL + '://' + process.env.VUE_APP_API_SUBDOMAIN + '.' + process.env.VUE_APP_DOMAIN + ':' + process.env.VUE_APP_API_PORT + '/v1/uploads/panda-docs/' + item.Upload.id + '/' + slug + '.pdf'
            },

            async fetchData(isRepeat = false){
                await this.$store.dispatch('onBoardingStore/loadChecklistItems');
                if(!isRepeat) await this.$store.dispatch("onBoardingStore/loadDocuments");
            },

            sign_documents(){

                let docs = [];
                if(!this.docs_to_sign || !this.docs_to_sign.length) this.errorSet(this.$options.name, "Please Choose documents to sign.");

                if(!Array.isArray(this.docs_to_sign)){
                    this.docs_to_sign = [this.docs_to_sign];

                }
                for(let i = 0; i < this.docs_to_sign.length; i++){
                    let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
                    if(!doc.completed){
                        docs.push(doc.Upload);
                    }
                }

                if(!docs.length){
                    return;
                }

                this.uploads_to_sign = docs;
                this.sign_process = true;


                // let docs = [];
                //
                // if(!this.docs_to_sign || !this.docs_to_sign.length) this.errorSet(this.$options.name, "Please Choose documents to sign.")
                // if(!Array.isArray(this.docs_to_sign)){
                //     this.docs_to_sign = [this.docs_to_sign];
                // }
                // docs = this.docs_to_sign.filter(item => !item.completed);
                // this.uploads_to_sign = docs;
                // this.sign_process = true;
            },

            async updateChecklist(item){

                this.$store.dispatch('onBoardingStore/saveChecklistItemCompleted', {
                    item,
                    completed: !!item.completed
                });

            },

            async completeUpload(params){

                if(params.uploads && params.uploads.length) {
                    this.selected_upload.completed = true;
                    this.selected_upload.upload_id = params.uploads[0].id;
                    this.selected_upload.Upload = params.uploads[0];
                  await this.$store.dispatch('onBoardingStore/saveChecklistItemCompleted', {
                      item: this.selected_upload,
                      completed: true
                  });


                    // for(let i = 0; i < this.docs_to_sign.length; i++){
                    //     let doc = this.signature_items.find(si => si.id === this.docs_to_sign[i]);
                    //     if(!doc.upload_id === this.selected_upload.upload_id){
                    //         this.docs_to_sign.splice(i,1);
                    //     }
                    // }


                  this.upload_document = false;
                  this.selected_upload = {};
                  // this.markComplete(this.selected_upload)
                }
            },
            //
            // async markComplete(item){
            //     console.log("item", item);
            //     item.completed = true;
            //     await api.put(this, api.LEASES + this.lease.id + '/checklist-item/' + item.id, {
            //         completed: true,
            //         upload_id: item.upload_id || null,
            //     });
            //
            //     await this.fetchData();
            // },
            //
            // async markIncompleted(item){
            //     item.completed = false;
            //     await api.put(this, api.LEASES + this.lease.id  + '/checklist-item/' + item.id, {
            //         completed: false
            //     });
            //     await this.fetchData();
            //
            // },

            async closeSignProcess(upload_id){
                this.sign_process = false;
                this.signer_id = null;
                this.uploads_to_sign = [];
                this.active_item = {};

            },

            async print(){
                let location =  await api.get(this, api.LEASES + this.lease.id + '/documents/', this.signature_items[0].document_id + '/generate', {}, 'print_loading');
            },

            closeSendWindow(){
                this.send_docs=false;
                this.to_send = {};
            },

            closeInvoiceGeneration(){
                this.invoiceGeneration=false;
            },

            send_documents(item, signer){
                this.send_docs = true;
                let phone =  signer.Contact && signer.Contact.Phones && signer.Contact.Phones.length ? signer.Contact.Phones.find(p => p.sms) : null;
                this.$set(signer, 'send_sms', !!phone);
                this.$set(signer, 'sms_number', phone? phone.phone : '');
                this.$set(signer, 'send_email', !!signer.Contact.email);
                this.$set(signer, 'email_address', signer.Contact.email);
                this.$set(signer, 'upload_id', item.Upload.id);
                this.$set(signer, 'lease_id', this.lease.id );
                this.$set(signer, 'item_id', item.id );
                this.$set(signer, 'property_id', this.property.id );
                this.to_send = signer;
            },

            upload(item){
              this.selected_upload = JSON.parse(JSON.stringify(item));
              this.upload_document = true;
            },

            async remove(item){

                let index = this.docs_to_sign.findIndex(d => d.id === item.id);
                if(index >= 0){
                    this.docs_to_sign.splice(index, 1);
                }
                this.$store.dispatch('onBoardingStore/regenerateItem', item);
            },

            email_copy(item){
                this.email_doc_copy = true;
                this.file = item;
            },

            async download_panda_doc(item){
                this.$http.get(
                    api.UPLOADS + 'panda-docs/' + item.Upload.id + '/download',
                    null,
                    { responseType: 'arraybuffer' }
                ).then(function (response) {
                    var blob = new Blob([response.body], {type:response.headers.get('content-type')});
                    var link = document.createElement('a');
                    link.href = window.URL.createObjectURL(blob);
                    link.download = item.Upload.name;
                    link.click();
                });

            },

            async finalize(){

                this.finalizeInProgress = true;

                let data = {
                    billed_months: this.billed_months,
                    // access_code: this.accessCode
                };
                data.payment = this.payment;
                if(!data.payment.payment_method_id && this.paymentMethod.type){
                    data.paymentMethod = this.paymentMethod;
                }

                data.total = this.totalSum;

                data.payment = {
                    id: this.payment.payment_id,
                    payment_method_id: this.payment.payment_method_id,
                    type: this.payment.type,
                    number: this.payment.number,
                    ref_name: this.payment.ref_name,
                    source: this.payment.source,
                    amount: this.payment.amount,
                    date: this.payment.date,
                    contact_id: this.payment.contact_id,
                    property_id: this.payment.property_id,
                    authorization: this.payment.authorization
                };
                data.threshold = this.threshold;

                let r = await api.post(this, api.LEASES + this.lease.id + '/finalize', data)
                data.payment.id = r.payment_id;
                data.payment.transaction_id = r.transaction_id;
                data.payment.amount = r.amount;
                this.$store.commit('onBoardingStore/setPayment', data.payment);
                this.saved = true;
                EventBus.$emit('unit_available_changed');
                this.$emit('saved');

                this.finalizeInProgress = false;
                await this.resetPropertiesInfo();
                this.fetchPropertyGroups({hardRefresh: true});
            },

            openInvocieGeneration(){
              let [key = {}] = Object.keys(this.template);
              let {invoiceSendDay = 0 } = this.template[key];
              let { bill_day = 0 } = this.lease;
              let bill_date = moment().set("date", bill_day);
              let difference = bill_date.diff(this.currentDate, 'days');
              if((difference <= invoiceSendDay) && difference > 0){
                this.invoiceGenerationDifference = difference;
                this.threshold = true;
                this.invoiceGeneration = true;
              } else {
                bill_date.add(1, 'M');
                let differenceNextMonth = bill_date.diff(this.currentDate, 'days');
                if(differenceNextMonth <= invoiceSendDay){
                  this.invoiceGenerationDifference = differenceNextMonth;
                  this.threshold = true;
                  this.invoiceGeneration = true;
                } else {
                  this.finalize();
                }
              }
            },

            goToLease(){

                this.$router.push('/contacts/' + this.contact.id);
                this.$emit('close', true);
            },


        }
    }
</script>

<style scoped>

    .insurance-container{
        border: 1px solid #C4CDD5;
        background: white;
        padding: 30px;
        border-radius: 4px;
        margin-bottom: 30px;
    }
    .policy-container{
        padding-top: 0;
        padding-bottom: 0;
    }
    .form-label{
        background: #F4F6F8;
        border-radius: 4px;
    }
    .insurance-option-row{
        border-bottom: 1px solid #C4CDD5;
        padding: 10px 20px;
    }
    .insurance-section-label{
        padding: 30px;
        font-weight: bold;
    }
    .insurance-container{
        padding: 30px 50px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }

    .new-tenant-walkthrough-footer{
        /* padding: 3px 0 3px 0; */
        background: #FFFFFF;
        border-top: 1px solid #e2e2e2;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: 100;
        left: 0;
        right: 0;
    }
    .services-section{
        padding: 20px 24px 100px 24px
    }

    .policy-container  .v-input.v-input--selection-controls.v-input--checkbox {
        margin-top: 0;
    }

    .signatures .v-input--selection-controls .v-input__control{
        flex-grow: 1;
    }

    .send-block{
        margin-top: 10px;
    }




</style>
