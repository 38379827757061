<template>
  <div class="charm-margin-top">
    <div class="charm-background" style="background:#004247">
  <span>
    <div v-if="$vuetify.breakpoint.lgAndUp"
      class="charm-textsize  mr-6 mt-1 d-flex align-center"
    >
      <div class="ml-4">
        <div
          class="d-flex align-center hb-pointer"
          @click="handleClick('reservations')"
        >
          <div
            class="mini-charm-header-counter white--text d-flex justify-center"
            :class="[(reservationsCount > 99 ? 'notification-count-99' : reservationsCount > 9 ? 'notification-count-9' : reservationsCount > 0 ? 'notification-count-1' :'notification-count-0'), show ? 'hide' : '']"
            align="center"
          >
            {{ reservationsCount > 99 ? "99+" : reservationsCount }}
          </div>
          <div
            class="ml-1"
            :class="
              active == 'reservations'
                ? 'white--text'
                : 'mini-charm-header-not-active-text-color'
            "
          >
            Reservations
          </div>
        </div>
        <div
          class="mt-1 mini-charm-underline"
          :class="{
            'mini-charm-underline-active-color': active == 'reservations'
          }"
        ></div>
      </div>
      <div class="ml-4">
        <div
          class="d-flex align-center hb-pointer"
          @click="handleClick('voicemails')"
        >
          <div
            class="mini-charm-header-counter white--text d-flex justify-center"
            :class="[(voicemailsCount > 99 ? 'notification-count-99' : voicemailsCount > 9 ? 'notification-count-9' : voicemailsCount > 0 ? 'notification-count-1' :'notification-count-0'), show ? 'hide' : '']"
            align="center"
          >
            {{ voicemailsCount > 99 ? "99+" : voicemailsCount }}
          </div>
          <div
            class="ml-1"
            :class="
              active == 'voicemails'
                ? 'white--text'
                : 'mini-charm-header-not-active-text-color'
            "
          >
            Voicemails
          </div>
        </div>
        <div
          class="mt-1 mini-charm-underline"
          :class="{
            'mini-charm-underline-active-color': active == 'voicemails'
          }"
        ></div>
      </div>
      <div class="ml-4">
        <div
          class="d-flex align-center hb-pointer"
          @click="handleClick('missed calls')"
        >
          <div
            class="mini-charm-header-counter white--text d-flex justify-center"
            :class="[(missedCallsCount > 99 ? 'notification-count-99' : missedCallsCount > 9 ? 'notification-count-9' : missedCallsCount > 0 ? 'notification-count-1' :'notification-count-0'), show ? 'hide' : '']"
            align="center"
          >
            {{ missedCallsCount > 99 ? "99+" : missedCallsCount }}
          </div>
          <div
            class="ml-1"
            :class="
              active == 'missed calls'
                ? 'white--text'
                : 'mini-charm-header-not-active-text-color'
            "
          >
            Missed Calls
          </div>
        </div>
        <div
          class="mt-1 mini-charm-underline"
          :class="{
            'mini-charm-underline-active-color': active == 'missed calls'
          }"
        ></div>
      </div>
      <div class="ml-4" v-if="showHide" style="margin-top:-7px;">
        <div>
          <hb-btn
                icon
                  v-if="show"
                  :key="show"
                  active-by-default
                  always-active
                  active-background-color="#004247"
                  color="#ffffff"
                  active-color="#ffffff"
                  hover-color="#ffffff"
                  hover-opacity
                  @click="handleShowHide(false)"
                  right
                  tooltip="Show Charm"
                >
                mdi-eye-off
                </hb-btn>
                <hb-btn
                  icon
                  v-if="!show"
                  
              
                  :key="show"
                  @click="handleShowHide(true)"
                  right
                  active-by-default
                  always-active
                  active-background-color="#004247"
                  color="#ffffff"
                  active-color="#ffffff"
                  hover-color="#ffffff"
                  hover-opacity
                  tooltip="Hide Charm"
                >
                mdi-eye
            </hb-btn>
        </div>
      </div>
      <div class="ml-4" v-if="showHide" style="margin-top:-7px;">
        <div>
          <hb-btn
                icon
                v-if="agentavailable"
                :key="agentavailable"
                @click='charmAvailability(false)'
                right
                active-by-default
                always-active
                active-background-color="#02AD0F"
                color="#ffffff"
                active-color="#ffffff"
                hover-color="#ffffff"
                hover-opacity
                tooltip="Set as unavailable"
              >
              mdi-headset
              </hb-btn>
              <hb-btn
                icon
                v-if="!agentavailable"
                :key="agentavailable"
                @click='charmAvailability(true)'
                right
                active-by-default
                always-active
                active-background-color="#FB4C4C"
                color="#ffffff"
                active-color="#ffffff"
                hover-color="#ffffff"
                hover-opacity
                tooltip="Set as available"
              >
              mdi-headset-off
          </hb-btn>
          
        </div>
      </div>
    </div>
    
    <div v-else class="ml-6 mr-3 mt-1 d-flex align-center">
      <div>
        <hb-menu>
          <template v-slot:menu-activator>
            <div
              class="mini-charm-header-counter white--text d-flex justify-center mt"
              :class="[(totalCount > 99 ? 'notification-count-99' : totalCount > 9 ? 'notification-count-9' : totalCount > 0 ? 'notification-count-1' : 'notification-count-0'), show ? 'hide' : '']"
              align="center"
            >
              {{ totalCount > 99 ? "99+" : totalCount }}
            </div>
          </template>

          <v-list style="background:#003135">
            <v-list-item
              :ripple="false"
              @click="handleClick('reservations')"
            >
              <div class="mt-1">
                <div class="d-flex align-center hb-pointer">
                  <div
                    class="mini-charm-header-counter white--text d-flex justify-center"
                    :class="[(reservationsCount > 99 ? 'notification-count-99' : reservationsCount > 9 ? 'notification-count-9' : reservationsCount > 0 ? 'notification-count-1' : 'notification-count-0'), show ? 'hide' : '']"
                    align="center"
                  >
                    {{ reservationsCount > 99 ? "99+" : reservationsCount }}
                  </div>
                  <div
                    class="ml-1"
                    :class="
                      active == 'reservations'
                        ? 'white--text'
                        : 'mini-charm-header-not-active-text-color'
                    "
                  >
                    Reservations
                  </div>
                </div>
                <div
                  class="mt-1 mini-charm-underline"
                  :class="{
                    'mini-charm-underline-active-color':
                      active == 'reservations'
                  }"
                ></div>
              </div>
            </v-list-item>

            <v-list-item
              :ripple="false"
              @click="handleClick('voicemails')"
            >
              <div class="mt-1">
                <div class="d-flex align-center hb-pointer">
                  <div
                    class="mini-charm-header-counter white--text d-flex justify-center"
                     :class="[(voicemailsCount > 99 ? 'notification-count-99' : voicemailsCount > 9 ? 'notification-count-9' : voicemailsCount > 0 ? 'notification-count-1' :'notification-count-0'), show ? 'hide' : '']"
                    align="center"
                  >
                    {{ voicemailsCount > 99 ? "99+" : voicemailsCount }}
                  </div>
                  <div
                    class="ml-1"
                    :class="
                      active == 'voicemails'
                        ? 'white--text'
                        : 'mini-charm-header-not-active-text-color'
                    "
                  >
                    Voicemails
                  </div>
                </div>
                <div
                  class="mt-1 mini-charm-underline"
                  :class="{
                    'mini-charm-underline-active-color': active == 'voicemails'
                  }"
                ></div>
              </div>
            </v-list-item>

            <v-list-item
              :ripple="false"
              @click="handleClick('missed calls')"
            >
              <div class="mt-1">
                <div class="d-flex align-center hb-pointer">
                  <div
                    class="mini-charm-header-counter white--text d-flex justify-center"
                     :class="[(missedCallsCount > 99 ? 'notification-count-99' : missedCallsCount > 9 ? 'notification-count-9' : missedCallsCount > 0 ? 'notification-count-1' :'notification-count-0'), show ? 'hide' : '']"
                    align="center"
                  >
                    {{ missedCallsCount > 99 ? "99+" : missedCallsCount }}
                  </div>
                  <div
                    class="ml-1"
                    :class="
                      active == 'missed calls'
                        ? 'white--text'
                        : 'mini-charm-header-not-active-text-color'
                    " 
                  >
                    Missed Calls
                  </div>
                </div>
                <div
                  class="mt-1 mini-charm-underline"
                  :class="{
                    'mini-charm-underline-active-color':
                      active == 'missed calls'
                  }"
                ></div>
              </div>
            </v-list-item>
          </v-list>
        </hb-menu>
      </div>
      <div v-if="showHide" class="ml-2 mr-n2 mt">
        <div>
          <hb-btn
                icon
                  v-if="show"
                  :key="show"
                  active-by-default
                  always-active
                  active-background-color="#004247"
                  color="#ffffff"
                  active-color="#ffffff"
                  hover-color="#ffffff"
                  hover-opacity
                  @click="handleShowHide(false)"
                  right
                  tooltip="Show Charm"
                >
                mdi-eye-off
                </hb-btn>
                <hb-btn
                  icon
                  v-if="!show"
                  
              
                  :key="show"
                  @click="handleShowHide(true)"
                  right
                  active-by-default
                  always-active
                  active-background-color="#004247"
                  color="#ffffff"
                  active-color="#ffffff"
                  hover-color="#ffffff"
                  hover-opacity
                  tooltip="Hide Charm"
                  
                >
                mdi-eye
            </hb-btn>
        </div>
      </div>
      <div v-if="showHide" class="ml-2 mr-n2 mt">
        <div>
          <hb-btn
                class="mdi-button"
                icon
                v-if="agentavailable"
                :key="agentavailable"
                @click='charmAvailability(false)'
                right
                active-by-default
                always-active
                active-background-color="#02AD0F"
                color="#ffffff"
                active-color="#ffffff"
                hover-color="#ffffff"
                hover-opacity
                tooltip="Set as unAvailable"
              >
              mdi-headset
              </hb-btn>
              <hb-btn
                icon
                v-if="!agentavailable"
                :key="agentavailable"
                @click='charmAvailability(true)'
                right
                active-by-default
                always-active
                active-background-color="#FB4C4C"
                color="#ffffff"
                active-color="#ffffff"
                hover-color="#ffffff"
                hover-opacity
                tooltip="Set as available"
              >
              mdi-headset-off
          </hb-btn>
          
        </div>
      </div>
    </div>
  </span>
</div>
</div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import api from '../../../../src/assets/api';
import { EventBus } from '../../../../src/EventBus.js';
export default {
  name: "MiniCharmHeader",
  data() {
    return {
      active: "",
      show: false,
      agentavailable: "",
      showHide: true,
      prevNotificationState: "",
      prevCallState: "",
      selectedTab: '',
      interval: null,
    };
  },
  props: {},
  components: {
},
  created() {
    EventBus.$on('update-agentAvailable', (newValue) => {
      this.agentavailable = newValue?.available ? true : false
    });
    this.prevNotificationState = this.isCharmNotificationToastOpen;
    this.prevCallState = this.isCharmCallToastOpen;
    this.showCharmNotificationToast({
      value: this.isCharmNotificationToastOpen,
      type: this.charmNotificationType,
      view: this.charmNotificationView
    });
    this.showCharmCallToast(this.isCharmCallToastOpen);
    this.agentavailable= this.charmAgentAvailable()
  },
  destroyed() {
    EventBus.$off('update-agentAvailable')
  },
  computed: {
    ...mapGetters({
      charmSubscribedAndFilteredProperties: 'charmSubscriptionStore/charmSubscribedProperties',
      charmSubscribedProperties: 'charmSubscriptionStore/charmSubscribedProperties',
      isCharmNotificationToastOpen: "charmNotificationStore/isCharmNotificationToastOpen",
      reservationsCount: "charmNotificationStore/reservationsNotificationsCount",
      missedCallsCount: "charmNotificationStore/missedCallNotificationsCount",
      voicemailsCount: "charmNotificationStore/voicemailsNotificationsCount",
      charmNotificationView: "charmNotificationStore/charmNotificationView",
      charmNotificationType: "charmNotificationStore/charmNotificationType",
      isCharmCallToastOpen: "charmCallStore/isCharmCallToastOpen",
      hasPermission: "authenticationStore/rolePermission",
      charmAgentAvailable: "authenticationStore/charmAgentAvailability",
      loggedInUser: "authenticationStore/getUserData",
      allPropertiesList: 'propertiesStore/properties',
      selectedProperties: "propertiesStore/filtered",
      allproperties:"propertiesStore/properties"
    }),
    totalCount() {
      return (
        this.reservationsCount + this.voicemailsCount + this.missedCallsCount
      );
    }
  },
  methods: {
    ...mapActions({
      showCharmNotificationToast: "charmNotificationStore/showCharmNotificationToast",
      getNotificationDataFromStore: "charmNotificationStore/getNotificationDataFromStore",
      showCharmCallToast: "charmCallStore/showCharmCallToast",
      setFollowUpTimer: 'charmNotificationStore/setFollowUpTimer',
      getMissedCallsNotifications: 'charmNotificationStore/getMissedCallsNotifications',
      getReservationNotifications: 'charmNotificationStore/getReservationNotifications',
      getVoicemailNotifications: 'charmNotificationStore/getVoicemailNotifications',
      setCharmAgentAvailability:'authenticationStore/setCharmAgentAvailability'
    }),
    async available(){
      await api.get(this.$app, api.CHARM_AVAILABLE_AGENT,{agent_id: this.loggedInUser.id}).then((data)=>{
      this.agentavailable= data.available
          })
          .catch((err)=>{
            console.log(err)
          })
        },
  
    handleClick(type) {
      if (this.active.length && this.active == type) {
        this.active = "";
        this.selectedTab = '';
        this.showCharmNotificationToast({ value: false, view: "list" });
      } else {
        this.active = type;
        this.selectedTab = type;
        this.setFollowUpTimer();
        // Follow up timer every 60 sec
        this.interval = setInterval(() => this.setFollowUpTimer(), 60000);
        if (type === "reservations") {
          this.showCharmReservations();
        }
        if (type === "missed calls") {
          this.showCharmMissedCalls();
        }
        if (type === 'voicemails') {
          this.showCharmVoicemails();
        }
      }
    },

    showCharmReservations() {
      if (this.reservationsCount) {
        this.showCharmNotificationToast({ value: true, type: "reservations", view: "list" });
        this.getNotificationDataFromStore({ type: "reservations", title: "Reservations" });
        this.getReservationNotifications(this.charmSubscribedAndFilteredProperties);
      } else {
        this.showCharmNotificationToast({ value: false, view: "list" });
        this.active = "";
      }
    },

    showCharmMissedCalls() {
      if (this.missedCallsCount) {
        this.showCharmNotificationToast({ value: true, type: "missedcalls", view: "list" });
        this.getNotificationDataFromStore({ type: "missed calls", title: "Missed Calls" });
        this.getMissedCallsNotifications(this.charmSubscribedAndFilteredProperties);
      } else {
        this.showCharmNotificationToast({ value: false, view: "list" });
        this.active = "";
      }
    },

    showCharmVoicemails() {
      if (this.voicemailsCount) {
        this.showCharmNotificationToast({ value: true, type: "voicemails", view: "list" });
        this.getNotificationDataFromStore({ type: "voicemails", title: "Voicemails" });
        this.getVoicemailNotifications(this.charmSubscribedAndFilteredProperties);
      } else {
        this.showCharmNotificationToast({ value: false, view: "list" });
        this.active = "";
      }
    },

    handleShowHide(status) {
      this.show=status
      if (this.show) {
        this.prevNotificationState = this.isCharmNotificationToastOpen;
        this.prevCallState = this.isCharmCallToastOpen;
        this.showCharmNotificationToast({ value: false, type: this.charmNotificationType, view: this.charmNotificationView });
        this.showCharmCallToast(false);
      } else {
        this.showCharmNotificationToast({ value: this.prevNotificationState, type: this.charmNotificationType, view: this.charmNotificationView });
        this.showCharmCallToast(this.prevCallState);
      }
    },
    charmAvailability(status){ 
      this.agentavailable=status
     
        if(status){
        if(this.hasPermission('mini_charm')) {
                    let payload = {
                    agent_id: this.loggedInUser.id,
                    availability: 'available',
                    // logged_in: true,
                    facilities: {
                        available: this.charmSubscribedProperties.filter(p => p.mini_charm_enabled )?.map(m => m.gds_id),
                        unavailable: []
                    },
                    from: 'login'
                }
                if(payload.facilities.available.length){
                  this.$store.dispatch('charmSubscriptionStore/setAvailableAgent', payload, {root: true});
                }                
                this.setCharmAgentAvailability(true)
        }
      } else{
        let payload = {
			  agent_id: this.loggedInUser.id,
			  availability: 'unavailable',
			  // logged_in: false,
			  from: 'logout',
			  property_ids: [],
			  facilities: {
				available: [],
				unavailable: this.charmSubscribedProperties.filter(p => p.mini_charm_enabled)?.map(m => m.gds_id)
			  }
			}
			this.$store.dispatch('charmSubscriptionStore/setAvailableAgent', payload, {root: true});
      this.setCharmAgentAvailability(false)
      }
    },
    getcharmagentStatus(){
      
      return this.charmAgentAvailable

    }
  },

  watch: {
    isCharmNotificationToastOpen() {
      if (this.isCharmNotificationToastOpen) {
        this.show = false;
        this.active = this.selectedTab;
      } else {
        this.active = "";
        clearInterval(this.interval);
        this.interval = null;
      }
    },
    isCharmCallToastOpen() {
      if (this.isCharmCallToastOpen) {
        this.show = false;
      }
    },
  }
};
</script>

<style scoped>
 
@media screen and (max-width : 1400px)
{
  .charm-textsize
  {
    font-size:11px;
  }
}

.mini-charm-header-counter {
  height: 16px;
  border-radius: 8px;
  font-size: 10px;
  font-weight: 500;
  position: relative;
  top: 0.5px;
}

.mini-charm-header-not-active-text-color {
  color: #a5b4bf;
}
.mini-charm-underline {
  height: 3px;
  background-color: transparent;
}
.mini-charm-underline-active-color {
  background-color: #ffffff;
}

.mini-charm-custom-switch
  >>> .hb-aviary-v-switch.v-input--is-label-active.v-input--switch--inset
  .v-input--switch__track {
  background-color: #ffd600 !important;
  opacity: 1 !important;
}

.mini-charm-custom-switch
  >>> .hb-aviary-v-switch.v-input--is-label-active.v-input--switch--inset
  .v-input--switch__thumb {
  color: #00848E !important;
}

.notification-count-99 {
  min-width: 26px;
  background-color: #f26500;
}

.notification-count-9 {
  min-width: 22px;
  background-color: #f26500;
}

.notification-count-1 {
  min-width: 16px;
  background-color: #f26500;
}

.notification-count-0{
  min-width: 13px;
  background-color: #A5B4BF;
}

.hide {
  background-color: #A5B4BF !important;
}

.mt {
  margin-top:-6px;
}
.charm-background {
    transition: all 300ms ease-in-out;
    padding: 6px 0px 3px 1px;
    border-radius: 40px;
    
    }
.charm-margin-top{
        margin-top: 8.5px;
        margin-bottom: 10.5px;
    }
</style>
