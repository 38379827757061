<template>
  <div class="tpa-wrapper">
    <hb-blade-header
      :title="`Tenant Payments Application (${getPropertyNumber()}${getPropertyCity()}${getPropertyAddress()})`"
      title-icon="mdi-currency-usd"
      half
      back-button-off
      @close="handleClose"
    >
    </hb-blade-header>
    <div class="tpa-stepper">
      <hb-stepper v-model="step">
        <hb-stepper-header>
          <hb-stepper-step
            step="1"
            @click="goToStep(1)"
            :complete="steps[0].status == 'step-complete'"
            :rules="[() => steps[0].status !== 'step-error']"
            >Entity Selection
          </hb-stepper-step>

          <v-divider></v-divider>

          <hb-stepper-step
            step="2"
            @click="goToStep(2)"
            :complete="steps[1].status == 'step-complete'"
            :rules="[() => steps[1].status !== 'step-error']"
            >Business Info
          </hb-stepper-step>

          <v-divider></v-divider>

          <hb-stepper-step
            step="3"
            @click="goToStep(3)"
            :complete="steps[2].status == 'step-complete'"
            :rules="[() => steps[2].status !== 'step-error']"
            >Know Your Customer
          </hb-stepper-step>

          <v-divider></v-divider>

          <hb-stepper-step
            step="4"
            @click="goToStep(4)"
            :complete="steps[3].status == 'step-complete'"
            :rules="[() => steps[3].status !== 'step-error']"
            >Bank Accounts
          </hb-stepper-step>

          <v-divider></v-divider>

          <hb-stepper-step
            step="5"
            @click="goToStep(5)"
            :complete="steps[4].status == 'step-complete'"
            :rules="[() => steps[4].status !== 'step-error']"
          >
            Agreements
          </hb-stepper-step>
        </hb-stepper-header>
        <div class="tpa-stepper-content">
          <div class="tpa-stepper-content-item">
            <v-stepper-items>
              <v-stepper-content step="1">
                <EntitySelection :form="form" ref="entitySelectionRef"/>
              </v-stepper-content>
              <v-stepper-content step="2">
                <BusinessInfo :form="form" ref="businessInfoRef" />
              </v-stepper-content>
              <v-stepper-content step="3">
                <KnowYourCustomer
                  :form="form"
                  :showSSNInfo="showSSNInfo"
                  @countChange="changeCount"
                  ref="knowYourCustomerRef"
                />
              </v-stepper-content>
              <v-stepper-content step="4">
                <BankAccounts :form="form" ref="bankAccountsRef" />
              </v-stepper-content>
              <v-stepper-content step="5">
                <Agreements :form="form" :property_id="getPropertyID " ref="agreementsRef" />
              </v-stepper-content>
            </v-stepper-items>
          </div>
        </div>
        <Footer
          :step="step"
          :saveAndCloseLoading="saveAndCloseLoading"
          :submitLoading="submitLoading"
          :continueLoading="continueLoading"
          @handleResetModal="handleResetModal"
          @back="back"
          @saveAndClose="saveAndClose"
          @continue="continueStep"
          @submitApplication="submitApplication"
        />
      </hb-stepper>
    </div>
    <!-- Close Confirmation Modal -->
    <hb-modal
      size="medium"
      title="Close Tenant Payments Application"
      v-model="showCloseModal"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          <p>
            You are about to close out of your Tenant Payments Application. All
            unsaved data will be lost.
          </p>
          <p class="ma-0">Are you sure you want to close the application?</p>
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="secondary" @click="confirmClose"
          >Close Without Saving</hb-btn
        >
        <hb-btn color="primary" @click="saveAndClose">Save & Close</hb-btn>
      </template>
    </hb-modal>
    <!-- Reset Modal -->
    <hb-modal
      size="medium"
      title="Reset Tenant Payments Application"
      v-model="showResetModal"
      confirmation
      show-help-link
    >
      <template v-slot:content>
        <div class="py-4 px-6">
          <p>
            You are about to reset your Tenant Payments Application. All unsaved
            data will be lost.
          </p>
          <p class="ma-0">Are you sure you want to reset the application?</p>
        </div>
      </template>
      <template v-slot:right-actions>
        <hb-btn color="primary" @click="resetApplication">Reset</hb-btn>
      </template>
    </hb-modal>
  </div>
</template>

<script>
import EntitySelection from "./EntitySelection.vue";
import BusinessInfo from "./BusinessInfo.vue";
import KnowYourCustomer from "./KnowYourCustomer.vue";
import BankAccounts from "./BankAccounts.vue";
import Misc from "./Misc.vue";
import Agreements from "./Agreements.vue";
import Footer from "./Footer.vue";
import { mapActions, mapGetters } from "vuex";
import { notificationMixin } from "@/mixins/notificationMixin.js";
import moment from "moment";
export default {
  name: "TenantPaymentsApplication",
  components: {
    EntitySelection,
    BusinessInfo,
    KnowYourCustomer,
    BankAccounts,
    Agreements,
    Footer,
  },
  mixins: [notificationMixin],
  data() {
    return {
      step: 1,
      showCloseModal: false,
      showResetModal: false,
      showSSNInfo: false,
      saveAndCloseLoading: false,
      submitLoading: false,
      continueLoading: false,
      form: {
        general: {
          entity_type: "",
          entity_sub_type:"",
          beneficiary_owners: 1,
          terms: null,
          currentTimeStamp: ""
        },
        business_information: {
          dba: "",
          website: "",
          business_email: "",
          ein: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          country: "USA",
          zip: "",
          legal_name: "",
          legal_address: {
            address: "",
            address2: "",
            city: "",
            state: "",
            country: "USA",
            zip: "",
          },
          facility_phone: {
            country_code: "1",
            phone: "",
            extension: "",
          },
          business_phone: {
            country_code: "1",
            phone: "",
            extension: "",
          },
          card_present: '',
          card_not_present: '',
          not_present_ecommerce: '',
        },
        account_owner: {
          first_name: "",
          last_name: "",
          dob: null,
          ssn: "",
          address: null,
          address2: null,
          city: null,
          state: null,
          country: "USA",
          zip: null,
        },
        beneficiaries: [
          {
            first_name: "",
            last_name: "",
            ssn: "",
            ownership: null,
            dob: null,
            address: "",
            address2: "",
            city: "",
            state: "",
            country: "USA",
            zip: "",
            same_as_director: false,
          },
        ],
        banking_information: {
          bank_account_holder_name: "",
          bank_ownership_type: "",
          bank_account_type: "",
          bank_name: "",
          bank_routing_num: "",
          bank_account_num: "",
          country_code: "",
        },
      },
      steps: [
        {
          number: 1,
          status: "step-active",
        },
        {
          number: 2,
          status: "",
        },
        {
          number: 3,
          status: "",
        },
        {
          number: 4,
          status: "",
        },
        {
          number: 5,
          status: "",
        },
      ],
      stepStatuses: [
        "completed",
        "empty",
        "partially-filled",
        "completed-without-ssn",
      ],
    };
  },
  computed: {
    ...mapGetters({
      getApplication: "tenantPaymentsApplicationStore/getApplication",
      getPropertyID: "tenantPaymentsApplicationStore/getPropertyID",
      getCurrentProperty: "tenantPaymentsApplicationStore/getCurrentProperty",
    }),
    isRegisteredBusiness() {
      return this.form.general.entity_type === "public_registered_business" || this.form.general.entity_type === "private_registered_business";
    },
  },
  async mounted() {
    await this.setFormData();
    this.validateStepsOnStart();
    this.$validator.extend('customAddressValidation', {
    validate: value => {
      const regex = /(?!(.*([pP][\.\s]*[oO0][\.\s]*[bB]\s*[oO0]\s*[xX]|[bB]\s*[oO0]\s*[xX][\s#]*[0-9])))^.+$/;
      return regex.test(value);
      },
      getMessage: field => 'The address cannot contain PO Box.',

    }); 
    this.$validator.extend('sumTo100', {
      validate: (value, { card_present, card_not_present, not_present_ecommerce }) => {
        const present = parseFloat(card_present) || 0;
        const notPresent = parseFloat(card_not_present) || 0;
        const ecommerce = parseFloat(not_present_ecommerce) || 0;

        return present + notPresent + ecommerce === 100;
      },
      getMessage: field => `The total of all the fields must sum up to 100.`,
    });
  },
  methods: {
    ...mapActions({
      fetchTenantPaymentsApplication:
        "tenantPaymentsApplicationStore/fetchTenantPaymentsApplication",
      updateTenantPaymentsApplication:
        "tenantPaymentsApplicationStore/updateTenantPaymentsApplication",
      createTenantPaymentsApplication:
        "tenantPaymentsApplicationStore/createTenantPaymentsApplication",
      validateRegistrationCode:
        "tenantPaymentsApplicationStore/validateRegistrationCode",
    }),
    getPropertyNumber() {
      return this.getCurrentProperty.number ? this.getCurrentProperty.number + ' - ' : '';
    },
    getPropertyCity() {
      return this.getCurrentProperty.city ? this.getCurrentProperty.city + ' - ' : '';
    },
    getPropertyAddress() {
      return this.getCurrentProperty.address ? this.getCurrentProperty.address : '';
    },
    changeCount(value) {
      if (value === 0 || value === 1) {
        if (this.form.beneficiaries.length === 1) {
          return;
        } else if (this.form.beneficiaries.length > 1) {
          this.form.beneficiaries.splice(1, this.form.beneficiaries.length);
        }
      } else {
        if (this.form.beneficiaries.length > value) {
          this.form.beneficiaries.splice(value, this.form.beneficiaries.length);
        } else if (value > this.form.beneficiaries.length) {
          let len = value - this.form.beneficiaries.length;
          for (let i = 0; i < len; i++) {
            this.form.beneficiaries.push({
              first_name: "",
              last_name: "",
              ssn: "",
              ownership: null,
              dob: moment().subtract(18, "years").format("YYYY-MM-DD"),
              address: "",
              address2: "",
              city: "",
              state: "",
              country: "USA",
              zip: "",
              same_as_director: false,
            });
          }
        }
      }
    },
    handleResetModal() {
      this.showResetModal = true;
    },
    handleClose() {
      this.showCloseModal = true;
    },
    confirmClose() {
      this.$emit("close");
    },
    back() {
      if (this.step-1 == 1){
        this.$refs.entitySelectionRef.call();
        const status = this.validateCurrentStep();
        if(status) {
          if (this.step < 5) {
            this.steps[this.step - 1].status = "step-complete";
          } else {
             this.steps[this.step - 1].status = "step-error";
          }
        }
      }
      if (this.step > 1) {
        if (this.isCompleted()) {
          this.steps[this.step - 1].status = "step-complete";
        } else {
          this.steps[this.step - 1].status = "step-error";
        }
        this.step--;
        this.resetValidator();
      }
    },
    async continueStep() {
      const status = await this.validateCurrentStep();
      if (status) {
        if (this.step < 5) {
          this.steps[this.step - 1].status = "step-complete";
          if (this.step === 3) {
            let businessInfoStatus =
              await this.$refs.businessInfoRef.$validator.validateAll(
                "business_info"
              );
            if (businessInfoStatus) {
              this.step++;
            } else {
              this.step = 2;
            }
          } else {
            this.step++;
          }
        }
      } else {
        this.steps[this.step - 1].status = "step-error";
      }
    },
    getSoleProprietorshipBeneficiary() {
      return [
        {
          first_name: this.form.account_owner.first_name,
          last_name: this.form.account_owner.last_name,
          ssn: this.form.account_owner.ssn,
          dob: this.form.account_owner.dob,
          address: this.form.account_owner.address,
          address2: this.form.account_owner.address2,
          city: this.form.account_owner.city,
          state: this.form.account_owner.state,
          country: this.form.account_owner.country,
          zip: this.form.account_owner.zip,
          ownership: 100,
          same_as_director: false,
        },
      ];
    },
    transformData(isSubmit = false) {
      const formData = {
        property_id: this.getPropertyID,
        general: {
          entity_type: this.form.general.entity_type,
          beneficiary_owners:
            this.form.general.entity_type === "sole_proprietorship"
              ? 0
              : this.form.general.beneficiary_owners,
          terms: this.form.general.terms,
          currentTimeStamp: this.form.general.currentTimeStamp,
          entity_sub_type: this.form.general.entity_sub_type
        },
        business_information: {
          ...this.form.business_information,
          ein: this.isRegisteredBusiness
            ? this.form.business_information.ein
            : this.form.account_owner.ssn,
          legal_name: this.isRegisteredBusiness
            ? this.form.business_information.legal_name
            : this.form.business_information.dba,
          business_phone: { ...this.form.business_information.facility_phone },
          card_present: this.form.business_information.card_present,
          card_not_present: this.form.business_information.card_not_present,
          not_present_ecommerce: this.form.business_information.not_present_ecommerce
        },
        account_owner: {
          ...this.form.account_owner,
          email_address: this.form.business_information.business_email,
        },
        beneficiaries:
          this.form.general.entity_type === "sole_proprietorship"
            ? this.getSoleProprietorshipBeneficiary()
            : this.form.beneficiaries,
        banking_info: {
          ownership_type: this.form.banking_information.bank_ownership_type,
          account_type: this.form.banking_information.bank_account_type,
          bank_name: this.form.banking_information.bank_name,
          routing_number: this.form.banking_information.bank_routing_num,
          account_number: this.form.banking_information.bank_account_num,
          country_code: this.form.banking_information.country_code,
          name_on_account:
            this.form.banking_information.bank_account_holder_name,
        },
        equipment: {
          will_purchase_equipment: false,
        },
        is_submit: isSubmit,
      };
      return formData;
    },
    setFormData() {
      if (this.getApplication) {
        this.form.general.entity_type = this.getApplication.general.entity_type;
        this.form.general.beneficiary_owners =
          this.getApplication.general.beneficiary_owners;
        this.form.general.terms = this.getApplication.general.terms;
        this.form.general.currentTimeStamp = this.getApplication.general.currentTimeStamp;
        this.form.general.entity_sub_type = this.getApplication.general.entity_sub_type;
        for (const key in this.getApplication.business_information) {
          if (Object.hasOwnProperty.call(this.form.business_information, key)) {
            if (
              typeof this.getApplication.business_information[key] ===
                "object" &&
              this.getApplication.business_information[key] !== null &&
              !Array.isArray(this.getApplication.business_information[key])
            ) {
              this.form.business_information[key] = {
                ...this.getApplication.business_information[key],
              };
            } else {
              this.form.business_information[key] =
                this.getApplication.business_information[key];
            }
          }
        }
        for (const key in this.getApplication.account_owner) {
          if (Object.hasOwnProperty.call(this.form.account_owner, key)) {
            this.form.account_owner[key] =
              this.getApplication.account_owner[key];
          }
        }
        if (this.getApplication.beneficiaries) {
          this.form.beneficiaries = this.getApplication.beneficiaries.map(
            (b) => {
              return {
                ...b,
                same_as_director: b.same_as_director === 1 ? true : false,
                ssn: "",
              };
            }
          );
        }
        this.form.banking_information.bank_account_holder_name =
          this.getApplication.bank_name_on_account === " "
            ? ""
            : this.getApplication.bank_name_on_account;
        this.form.banking_information.bank_ownership_type =
          this.getApplication.bank_ownership_type;
        this.form.banking_information.bank_account_type =
          this.getApplication.bank_account_type;
        this.form.banking_information.bank_name = this.getApplication.bank_name;
        this.form.banking_information.bank_routing_num =
          this.getApplication.bank_routing_num;
        this.form.banking_information.bank_account_num =
          this.getApplication.bank_account_num;
        this.form.banking_information.country_code =
          this.getApplication.bank_country_code;
      } else {
        this.setDefaultDates();
      }
    },
    setDefaultDates() {
      this.form.account_owner.dob = moment()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
      // In the start we have only one beneficiary
      this.form.beneficiaries[0].dob = moment()
        .subtract(18, "years")
        .format("YYYY-MM-DD");
    },
    async goToStep(stepNumber) {
      if (stepNumber >= this.step) {
        return;
      }
      if (stepNumber == 1){
        this.$refs.entitySelectionRef.call()
      }
      this.step = stepNumber;
      this.resetValidator();
    },
    resetValidator() {
      switch (this.step) {
        case 1:
          this.$refs.entitySelectionRef.$validator.reset();
          this.$refs.businessInfoRef.$validator.reset();
          this.$refs.knowYourCustomerRef.$validator.reset();
          this.$refs.bankAccountsRef.$validator.reset();
          this.$refs.agreementsRef.$validator.reset();
          break;
        case 2:
          this.$refs.businessInfoRef.$validator.reset();
          this.$refs.knowYourCustomerRef.$validator.reset();
          this.$refs.bankAccountsRef.$validator.reset();
          this.$refs.agreementsRef.$validator.reset();
          break;
        case 3:
          this.$refs.knowYourCustomerRef.$validator.reset();
          this.$refs.bankAccountsRef.$validator.reset();
          this.$refs.agreementsRef.$validator.reset();
          break;
        case 4:
          this.$refs.bankAccountsRef.$validator.reset();
          this.$refs.agreementsRef.$validator.reset();
          break;
        case 5:
          this.$refs.agreementsRef.$validator.reset();
          break;
        default:
          break;
      }
      return true;
    },
    resetForm() {
      this.form = {
        general: {
          entity_type: "",
          entity_sub_type: "",
          beneficiary_owners: 1,
          terms: null,
          currentTimeStamp: ''
        },
        business_information: {
          dba: "",
          website: "",
          business_email: "",
          ein: "",
          address: "",
          address2: "",
          city: "",
          state: "",
          country: "USA",
          zip: "",
          legal_name: "",
          legal_address: {
            address: "",
            address2: "",
            city: "",
            state: "",
            country: "USA",
            zip: "",
          },
          facility_phone: {
            country_code: "1",
            phone: "",
            extension: "",
          },
          business_phone: {
            country_code: "1",
            phone: "",
            extension: "",
          },
          card_present: '',
          card_not_present: '',
          not_present_ecommerce: ''
        },
        account_owner: {
          first_name: "",
          last_name: "",
          dob: null,
          ssn: "",
          address: null,
          address2: null,
          city: null,
          state: null,
          country: "USA",
          zip: null,
        },
        beneficiaries: [
          {
            first_name: "",
            last_name: "",
            ssn: "",
            ownership: null,
            dob: null,
            address: "",
            address2: "",
            city: "",
            state: "",
            country: "USA",
            zip: "",
            same_as_director: false,
          },
        ],
        banking_information: {
          bank_account_holder_name: "",
          bank_ownership_type: "",
          bank_account_type: "",
          bank_name: "",
          bank_routing_num: "",
          bank_account_num: "",
        },
      };
    },
    async resetApplication() {
      try {
        await this.fetchTenantPaymentsApplication({
          property_id: this.getPropertyID,
          current_property: this.getCurrentProperty
        });
      } catch (error) {
        this.resetForm();
      } finally {
        this.resetForm();
        this.setFormData();
        this.step = 1;
        this.$refs.entitySelectionRef.$validator.reset();
        this.$refs.businessInfoRef.$validator.reset();
        this.$refs.knowYourCustomerRef.$validator.reset();
        this.$refs.bankAccountsRef.$validator.reset();
        this.$refs.agreementsRef.$validator.reset();
        this.steps.forEach((element, index) => {
          if (index === 0) {
            element.status = "step-active";
          } else {
            element.status = "";
          }
        });
        this.showResetModal = false;
      }
    },
    getEntityStepStatus() {
      return this.form.general.entity_type
        ? this.stepStatuses[0]
        : this.stepStatuses[1];
    },
    getBusinessInfoStepStatus() {
      let isBusinessCompleted = true;
      let isBusinessPartiallyCompleted = false;
      let keys = [
        "ein",
        "legal_name",
        "legal_address",
        "business_phone",
        "facility_phone",
      ];
      let optionalFields = ["address2", "extension", "country_code"];
      for (const key in this.form.business_information) {
        if (!keys.includes(key)) {
          if (this.form.business_information[key]) {
            isBusinessPartiallyCompleted = true;
          } else if (!optionalFields.includes(key)) {
            isBusinessCompleted = false;
          }
        }
      }
      for (const key in this.form.business_information.facility_phone) {
        if (this.form.business_information.facility_phone[key]) {
          isBusinessPartiallyCompleted = true;
        } else if (!optionalFields.includes(key)) {
          isBusinessCompleted = false;
        }
      }
      if (this.isRegisteredBusiness) {
        if (
          !this.form.business_information.ein ||
          !this.form.business_information.legal_name ||
          // !this.form.business_information.business_phone.country_code ||
          // !this.form.business_information.business_phone.phone ||
          !this.form.business_information.legal_address.address ||
          !this.form.business_information.legal_address.city ||
          !this.form.business_information.legal_address.state ||
          !this.form.business_information.legal_address.country ||
          !this.form.business_information.legal_address.zip
        ) {
          isBusinessCompleted = false;
        }
        if (
          this.form.business_information.ein ||
          this.form.business_information.legal_name ||
          // this.form.business_information.business_phone.country_code ||
          // this.form.business_information.business_phone.phone ||
          // this.form.business_information.business_phone.extension ||
          this.form.business_information.legal_address.address ||
          this.form.business_information.legal_address.address2 ||
          this.form.business_information.legal_address.city ||
          this.form.business_information.legal_address.state ||
          this.form.business_information.legal_address.country ||
          this.form.business_information.legal_address.zip
        ) {
          isBusinessPartiallyCompleted = true;
        }
      }
      return isBusinessCompleted
        ? this.stepStatuses[0]
        : isBusinessPartiallyCompleted
        ? this.stepStatuses[2]
        : this.stepStatuses[1];
    },
    getKnowYourCustomerStepStatus() {
      let isCustomerCompleted = true;
      let isCustomerPartiallyCompleted = false;
      let isCustomerCompletedWithoutSSN = true;
      let optionalFields = ["address2"];
      for (const key in this.form.account_owner) {
        if (this.form.account_owner[key]) {
          isCustomerPartiallyCompleted = true;
        } else if (!optionalFields.includes(key)) {
          isCustomerCompleted = false;
          if (key !== "ssn") {
            isCustomerCompletedWithoutSSN = false;
          }
        }
      }
      if (this.form.general.entity_type !== "sole_proprietorship") {
        this.form.beneficiaries.forEach((element) => {
          for (const key in element) {
            if (
              element[key] &&
              Object.hasOwnProperty.call(this.form.account_owner, key)
            ) {
              isCustomerPartiallyCompleted = true;
            } else if (
              !element[key] &&
              Object.hasOwnProperty.call(this.form.account_owner, key) &&
              !optionalFields.includes(key)
            ) {
              isCustomerCompleted = false;
              if (key !== "ssn") {
                isCustomerCompletedWithoutSSN = false;
              }
            }
          }
        });
      }
      return isCustomerCompleted
        ? this.stepStatuses[0]
        : isCustomerCompletedWithoutSSN
        ? this.stepStatuses[3]
        : isCustomerPartiallyCompleted
        ? this.stepStatuses[2]
        : this.stepStatuses[1];
    },
    getBankAccountStepStatus() {
      let isBankCompleted = true;
      let isBankPartiallyFilled = false;
      for (const key in this.form.banking_information) {
        if (this.form.banking_information[key]) {
          isBankPartiallyFilled = true;
        } else {
          isBankCompleted = false;
        }
      }
      return isBankCompleted
        ? this.stepStatuses[0]
        : isBankPartiallyFilled
        ? this.stepStatuses[2]
        : this.stepStatuses[1];
    },
    getAgreementStepStatus() {
      return this.form.general.terms
        ? this.stepStatuses[0]
        : this.stepStatuses[1];
    },
    isCompleted() {
      switch (this.step) {
        case 1:
          return this.getEntityStepStatus() === this.stepStatuses[0];
        case 2:
          return this.getBusinessInfoStepStatus() === this.stepStatuses[0];
        case 3:
          return this.getKnowYourCustomerStepStatus() === this.stepStatuses[0];
        case 4:
          return this.getBankAccountStepStatus() === this.stepStatuses[0];
        case 5:
          return this.getAgreementStepStatus() === this.stepStatuses[0];
        default:
          break;
      }
      return false;
    },
    async validateStepsOnStart() {
      let status = true;
      if (this.getApplication) {
        status = this.getEntityStepStatus();
        if (status === this.stepStatuses[0]) {
          this.steps[0].status = "step-complete";
          status = this.getBusinessInfoStepStatus();
          if (status === this.stepStatuses[0]) {
            this.steps[1].status = "step-complete";
            this.steps[2].status = "step-error";
            this.showSSNInfo = true;
            this.step = 3;
          } else {
            this.step = 2;
            this.steps[1].status = "step-error";
          }
          status = this.getBankAccountStepStatus();
          if (status === this.stepStatuses[0]) {
            this.steps[3].status = "step-complete";
          } else if (status === this.stepStatuses[2]) {
            this.steps[3].status = "step-error";
          }
          status = this.getAgreementStepStatus();
          if (status === this.stepStatuses[0]) {
            this.steps[4].status = "step-complete";
          }
          if (
            this.steps[4].status === "step-complete" &&
            this.steps[3].status === ""
          ) {
            this.steps[3].status = "step-error";
          }
          if (
            (this.steps[3].status === "step-complete" ||
              this.steps[4].status === "step-complete") &&
            this.steps[2].status === ""
          ) {
            this.steps[2].status = "step-error";
          }
        }
      }
    },
    async validateCurrentStep() {
      let status = true;
      switch (this.step) {
        case 1:
          status = await this.$refs.entitySelectionRef.$validator.validate(
            "default.entity_selection"
          );
          console.log('entity_selection',status);
          if (this.form.general.entity_type === 'public_registered_business') {
          if(this.form.general.entity_sub_type != 'llc-publicly_traded' && this.form.general.entity_sub_type != 'c-corp-publicly_traded') {
              this.showMessageNotification({type:'error', list: ['The entity subtype field is required.'] });
              status = false;
            }
          } else if(this.form.general.entity_type === 'private_registered_business') {
              if(this.form.general.entity_sub_type != 'llc-non_publicly_traded' && this.form.general.entity_sub_type != 'c-corp-non_publicly_traded') {
                  this.showMessageNotification({type:'error', list: ['The entity subtype field is required.'] });
                  status = false;
                }
          }
          console.log('status',status);
          break;
        case 2:
          status = await this.$refs.businessInfoRef.$validator.validateAll(
            "business_info"
          );
          break;
        case 3:
          status = await this.$refs.knowYourCustomerRef.$validator.validateAll(
            "account_owner_info"
          );
          if (status) {
            this.showSSNInfo = false;
          }
          break;
        case 4:
          status = await this.$refs.bankAccountsRef.$validator.validateAll(
            "banking_info"
          );
          break;
        case 5:
          status = await this.$refs.agreementsRef.$validator.validateAll(
            "agreements_info"
          );
          break;
        default:
          break;
      }
      return status;
    },
    async saveAndClose() {
      try {
        const status = await this.validateCurrentStep();
        if (status) {
        this.saveAndCloseLoading = true;
        const formData = this.transformData();
        if (this.getApplication && this.getApplication.id) {
          await this.updateTenantPaymentsApplication({
            form: formData,
          });
        } else {
          await this.createTenantPaymentsApplication({
            form: formData,
          });
        }
        this.showMessageNotification({
          type: "success",
          description:
            "You have successfully saved your Tenant Payments application. You can resume at anytime.",
        });
        this.$emit("close");
      }else {
        this.steps[this.step - 1].status = "step-error";
      }
      } catch (error) {
        this.showMessageNotification({ description: error });
      } finally {
        this.saveAndCloseLoading = false;
      }
    },
    async submitApplication() {
      try {
        this.submitLoading = true;
        const status = await this.validateCurrentStep();
        if (status) {
          const formData = this.transformData(true);
          if (this.getApplication && this.getApplication.id) {
            await this.updateTenantPaymentsApplication({
              form: formData,
            });
          } else {
            await this.createTenantPaymentsApplication({
              form: formData,
            });
          }
          this.showMessageNotification({
            type: "success",
            description:
              "You have successfully submitted your Tenant Payments application.",
          });
          this.$emit("close");
        }
      } catch (error) {
        this.showMessageNotification({ description: error });
      } finally {
        this.submitLoading = false;
      }
    },
  },
  watch: {
    'form.general.entity_type'(val) {
      this.resetForm();
      this.setFormData();
      this.form.general.entity_type = val;
    }
  }
};
</script>

<style lang="scss" scoped>
.tpa-wrapper {
  position: relative;
  background-color: #f9fafb;
  height: 100%;

  .tpa-stepper {
    .v-stepper {
      background-color: #f9fafb;
      ::v-deep .v-expansion-panel {
        border: 1px solid #dfe3e8;
      }
    }
    .v-stepper__header {
      background-color: #ffffff;
    }
  }
  .tpa-stepper-content {
    background-color: #f9fafb;
    margin-bottom: 60px;
  }
}
</style>
