<template>
    <v-container dense>
        <v-row class="px-6">
            <v-col cols="12" sm="6" class="pt-5 px-3">
                <span flat style="max-height:150px;" v-if="company.logo">
                    <embed v-if="getImageType(company.logo) === 'svg'" :src="company.logo" type="image/svg+xml" :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'">
                    <img v-else class="text-capitalize" :src="company.logo" :alt="company.name" :width="$vuetify.breakpoint.mdAndUp ? '70%' : '100%'">
                </span>
                <span class="text-h6 font-weight-medium" v-else>
                {{company.name}}
                </span>
            </v-col>
            <v-col cols="12" sm="6" class="d-flex flex-column justify-center align-end"  v-if="property">
                <span class="subtitle-1 font-weight-medium text-capitalize">{{property.name}}</span>
                <span class="subtitle-2" v-if="property.Emails && property.Emails.length">{{property.Emails[0].email}}</span>
                <span class="subtitle-2" v-if="property.Phones && property.Phones.length">{{property.Phones[0].phone | formatPhone}}</span>
                <span class="subtitle-2" v-if="property.Address">{{ property.Address | formatAddress(',', true) }}</span>
            </v-col>
        </v-row>
        <v-row class="mx-0 mt-6 px-0">
            <v-col cols="12" md="6" class="align-end d-flex px-0">
                <span class="hb-header-font-size-receipt font-weight-medium ml-3">Transfer Receipt</span>
            </v-col>
            <v-col cols="12" md="6" class="align-center d-flex justify-end text-h5 font-weight-medium px-0">
                <span class="pr-3">Transfer Date:</span>{{ transfer.date | formatDateCustom('MMM DD, YYYY')}}
            </v-col>
        </v-row>
        <v-divider class="mx-2"></v-divider>
        <v-row class="mt-4">
            <v-col>
                <v-card outlined>
                    <v-card-title class="px-6 py-3" primary-title>
                        <span class="subtitle-1 font-weight-medium"
                            >Bill to</span
                        >
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                        class="py-4 px-6 d-flex flex-column"
                        style="color: #101318"
                        v-if="contact"
                    >
                        <span
                            class="subtitle-2 font-weight-regular text-capitalize"
                            >{{ contact.first }} {{ contact.last }}
                        </span>
                        <span class="subtitle-2 font-weight-regular">{{
                            contact.email
                        }}</span>
                        <span
                            class="subtitle-2 font-weight-regular"
                            v-if="contact.Phones && contact.Phones.length"
                            >{{ contact.Phones[0].phone | formatPhone }}</span
                        >
                        <span
                            class="subtitle-2 text-capitalize font-weight-regular"
                            v-if="contact.Addresses && contact.Addresses.length"
                            >{{ contact.Addresses[0].Address.address }}
                            {{ contact.Addresses[0].Address.address2 }}</span
                        >
                        <span
                            class="subtitle-2 text-capitalize font-weight-regular"
                            v-if="contact.Addresses && contact.Addresses.length"
                            >{{ contact.Addresses[0].Address.city }}
                            {{ contact.Addresses[0].Address.state }}
                            {{ contact.Addresses[0].Address.zip }}</span
                        >
                    </v-card-text>
                </v-card>
            </v-col>
            <v-col>
                <v-card outlined>
                    <v-card-title class="px-6 py-3" primary-title>
                        <span class="subtitle-1 font-weight-medium"
                            >Payment Info</span
                        >
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                        class="py-4 px-6 d-flex flex-column"
                        style="color: #101318"
                    >
                        <span class="subtitle-2 text-capitalize font-weight-regular" v-if="transferOutBalanceApplied > 0">
                            Credit<br />
                            Date of Credit Issue: {{ transfer.date | formatDate }}<br />
                            <br />
                        </span>
                        <span
                            class="subtitle-2 text-capitalize font-weight-regular"
                            v-if="
                                payment &&
                                payment.PaymentMethod &&
                                payment.PaymentMethod.type === 'Cash'
                            "
                        >
                            Cash Payment<br />
                            Reference Name: {{ payment.ref_name }}
                        </span>
                        <span
                            class="subtitle-2 text-capitalize font-weight-regular"
                            v-if="
                                payment &&
                                payment.PaymentMethod &&
                                payment.PaymentMethod.type === 'Check'
                            "
                        >
                            Check Payment<br />
                            Reference Name: {{ payment.ref_name }}<br />
                            Check Number: {{ payment.number }}
                        </span>
                        <span
                            class="subtitle-2 text-capitalize font-weight-regular"
                            v-if="
                                payment &&
                                payment.PaymentMethod &&
                                payment.PaymentMethod.type.toLowerCase() === 'giftcard'
                            "
                        >
                            Gift Card Payment<br />
                            Reference Name: {{ payment.ref_name }}<br />
                            Gift Card Number: {{ payment.number }}
                        </span>
                        <span
                            class="subtitle-2 font-weight-regular"
                            v-if="
                                payment &&
                                payment.PaymentMethod &&
                                payment.PaymentMethod.type === 'card'
                            "
                        >
                            Credit Card Payment<br />
                            <span v-if="payment.PaymentMethod.card_type"
                                >{{
                                    payment.PaymentMethod.card_type
                                        | capitalize
                                }}<br
                            /></span>
                            xxxx-xxxx-xxxx-{{ payment.PaymentMethod.card_end
                            }}<br />
                        </span>
                        <span
                            class="subtitle-2 text-capitalize font-weight-regular"
                            v-if="
                                payment &&
                                payment.PaymentMethod &&
                                payment.PaymentMethod.type === 'ach'
                            "
                        >
                            ACH<br />
                            Reference Name: {{ payment.ref_name }}<br />
                            Checking Account: XXXX{{
                                payment.PaymentMethod.card_end
                            }}
                        </span>
                        <span
                            class="subtitle-2 text-capitalize font-weight-regular"
                            v-if="
                                payment &&
                                payment.PaymentMethod &&
                                payment.PaymentMethod.type === 'ACH'
                            "
                        >
                            ACH<br />
                            Reference Name: {{ payment.ref_name }}<br />
                            Checking Account: XXXX{{
                                payment.PaymentMethod.card_end
                            }}
                        </span>
                        <span
                            class="subtitle-2 text-capitalize font-weight-regular"
                            v-if="payment && payment.transaction_id && isAdmin"
                        >
                            <span
                                class="subtitle-2 text-capitalize font-weight-regular"
                                ><br />Transaction ID:
                                {{ payment.transaction_id }}</span
                            >
                        </span>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col>
                <v-card outlined>
                    <v-card-title class="px-6 py-3" primary-title>
                        <span class="subtitle-1 font-weight-medium"
                            >Charges</span
                        >
                    </v-card-title>
                    <v-divider></v-divider>
                    <span v-if="move_out_invoices.length !== 0">
                        <span
                            v-for="(invoice, i) in move_out_invoices"
                            :key="'invoice' + i"
                        >
                            <v-card-text
                                class="px-1 py-1"
                                style="color: #101318"
                            >
                                <v-row>
                                    <v-col class="pr-2 pl-8">
                                        <span
                                            class="text-caption"
                                            style="color: #637381"
                                            >Invoice #{{ invoice.number
                                            }}<span v-if="invoice.due">
                                                (Due:
                                                {{
                                                    invoice.due
                                                        | formatDateCustom(
                                                            "MMM DD, YYYY"
                                                        )
                                                }})</span
                                            ></span
                                        ><br />
                                        <span
                                            class="text-caption text-capitalize font-weight-medium"
                                            v-if="
                                                invoice.Lease &&
                                                invoice.Lease.Unit
                                            "
                                            >Space #
                                            {{ invoice.Lease.Unit.number }}<br
                                        /></span>
                                        <span
                                            class="text-caption text-capitalize font-weight-regular"
                                            v-for="(
                                                lines, i
                                            ) in invoice.InvoiceLines"
                                            :key="'invoice-lines' + i"
                                        >
                                            <span v-if="lines.Credit.total > 0">
                                                <span v-if="lines.service_id">{{
                                                    lines.Service.name
                                                }}</span>
                                                <span v-else>{{
                                                    lines.Product.name
                                                }}</span>

                                                <span
                                                    v-if="
                                                        lines.Product
                                                            .default_type ==
                                                            'rent' ||
                                                        lines.Product
                                                            .default_type ==
                                                            'insurance'
                                                    "
                                                >
                                                    ({{
                                                        lines.Credit.prorate_date
                                                            | formatDateCustom(
                                                                "MMM DD, YYYY"
                                                            )
                                                    }}
                                                    -
                                                    {{
                                                        lines.Credit.period_end_date
                                                            | formatDateCustom(
                                                                "MMM DD, YYYY"
                                                            )
                                                    }})
                                                </span>
                                                <span
                                                    v-if="
                                                        lines.Product
                                                            .default_type ==
                                                            'product' &&
                                                        lines.qty > 1
                                                    "
                                                >
                                                    (Qty: {{ lines.qty }})</span
                                                >
                                                <span
                                                    style="float: right"
                                                    class="pr-6"
                                                    >{{
                                                        lines.Credit.total
                                                            | formatMoney
                                                    }}</span
                                                ><br />
                                            </span>
                                        </span>
                                        <div
                                            class="my-4 mr-6"
                                            style="
                                                border-bottom: 1px solid #e2e2e2;
                                            "
                                        ></div>
                                        <span class="text-caption text-capitalize font-weight-medium">
                                            Transfer Out Balance 
                                        </span>
                                        <span style="float: right" class="pr-6">
                                            <span class="font-weight-medium">
                                                ({{ invoice.credit | formatMoney }})
                                            </span> 
                                        </span>
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </span>
                    </span>
                    <span v-else>
                        <v-card-text class="px-1 py-1" style="color: #101318">
                            <v-row>
                                <v-col class="pr-2 pl-8">
                                    <span
                                        class="text-caption text-capitalize font-weight-medium"
                                        v-if="transfer && transfer.FromLease"
                                        >Space #
                                        {{ transfer.FromLease.Unit.number }}<br
                                    /></span>
                                    <div
                                        class="my-4 mr-6"
                                        style="border-bottom: 1px solid #e2e2e2"
                                    ></div>
                                    <span
                                        class="text-caption text-capitalize font-weight-medium"
                                        >Transfer Out Balance</span
                                    >
                                    <span style="float: right" class="pr-6"
                                        ><span class="font-weight-medium"
                                            >({{ 0 | formatMoney }})</span
                                        ></span
                                    >
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider class="ma-0 pa-0"></v-divider>
                    </span>
                    <span v-if="invoices.length !== 0">
                        <span
                            v-for="(invoice, i) in invoices"
                            :key="'invoice' + i"
                        >
                            <v-card-text
                                class="px-1 py-1"
                                style="color: #101318"
                            >
                                <v-row>
                                    <v-col class="pr-2 pl-8">
                                        <span
                                            class="text-caption"
                                            style="color: #637381"
                                            >Invoice #{{ invoice.number
                                            }}<span v-if="invoice.due">
                                                (Due:
                                                {{
                                                    invoice.due
                                                        | formatDateCustom(
                                                            "MMM DD, YYYY"
                                                        )
                                                }})</span
                                            ></span
                                        ><br />
                                        <span
                                            class="text-caption text-capitalize font-weight-medium"
                                            v-if="
                                                invoice.Lease &&
                                                invoice.Lease.Unit
                                            "
                                            >Space #
                                            {{ invoice.Lease.Unit.number }}<br
                                        /></span>
                                        <span
                                            class="text-caption text-capitalize font-weight-regular"
                                            v-for="(
                                                lines, i
                                            ) in invoice.InvoiceLines"
                                            :key="'invoice-lines' + i"
                                        >
                                            <span v-if="lines.service_id">{{
                                                lines.Service.name
                                            }}</span>
                                            <span v-else>{{
                                                lines.Product.name
                                            }}</span>

                                            <span
                                                v-if="
                                                    lines.Product
                                                        .default_type == 'rent'
                                                "
                                            >
                                                ({{
                                                    lines.start_date
                                                        | formatDateCustom(
                                                            "MMM DD, YYYY"
                                                        )
                                                }}
                                                -
                                                {{
                                                    lines.end_date
                                                        | formatDateCustom(
                                                            "MMM DD, YYYY"
                                                        )
                                                }})
                                            </span>
                                            <span
                                                v-if="
                                                    lines.Product
                                                        .default_type ==
                                                        'product' &&
                                                    lines.qty > 1
                                                "
                                            >
                                                (Qty: {{ lines.qty }})</span
                                            >
                                            <span
                                                style="float: right"
                                                class="pr-6"
                                                >{{
                                                    lines.cost | formatMoney
                                                }}</span
                                            ><br />
                                        </span>
                                        <span v-if="invoice.total_tax !== 0"
                                            >Taxes<span
                                                style="float: right"
                                                class="pr-6"
                                                >{{
                                                    invoice.total_tax
                                                        | formatMoney
                                                }}</span
                                            ><br
                                        /></span>
                                        <span v-if="invoice.discounts !== 0"
                                            >Discounts<span
                                                style="float: right"
                                                class="pr-6"
                                                >-{{
                                                    invoice.discounts
                                                        | formatMoney
                                                }}</span
                                            ><br
                                        /></span>

                                        <div
                                            class="my-4 mr-6"
                                            style="
                                                border-bottom: 1px solid #e2e2e2;
                                            "
                                        ></div>
                                        <span
                                            class="text-caption text-capitalize font-weight-medium"
                                            >Invoice Total</span
                                        >
                                        <span style="float: right" class="pr-6"
                                            ><span class="font-weight-medium">{{
                                                (invoice.total_due - invoice.total_discounts) | formatMoney
                                            }}</span></span
                                        ><br />
                                    </v-col>
                                </v-row>
                            </v-card-text>
                            <v-divider class="ma-0 pa-0"></v-divider>
                        </span>
                    </span>
                    <span v-if="invoices.length === 0">
                        <v-card-text class="px-1 py-1" style="color: #101318">
                            <v-row>
                                <v-col class="pr-2 pl-8">
                                    <span
                                        class="text-caption font-weight-regular"
                                        >Charges
                                        <span style="float: right" class="pr-6"
                                            >N/A</span
                                        ><br />
                                    </span>

                                    <div
                                        class="my-4 mr-6"
                                        style="border-bottom: 1px solid #e2e2e2"
                                    ></div>
                                    <span
                                        class="text-caption text-capitalize font-weight-medium"
                                        >Payment</span
                                    >
                                    <span style="float: right" class="pr-6"
                                        ><span class="font-weight-medium"
                                            >-{{
                                                payment.amount | formatMoney
                                            }}</span
                                        ></span
                                    ><br />
                                </v-col>
                            </v-row>
                        </v-card-text>
                        <v-divider class="ma-0 pa-0"></v-divider>
                    </span>
                    <v-card-text
                        class="px-3 py-5"
                        style="color: #101318; background: #f9fafb"
                    >
                        <v-row>
                            <v-col cols="6" md="10" class="pa-0 pb-1 pl-3 text-right">
                                <span class="text-capitalize font-weight-medium"
                                    >Total Due</span
                                >
                            </v-col>
                            <v-col
                                cols="6"
                                md="2"
                                class="pa-0 pb-1 pl-3 pr-6 text-right"
                            >
                                <span class="font-weight-medium">{{
                                    calculateTotalDue | formatMoney
                                }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="10" class="pa-0 pb-1 pl-3 text-right">
                                <span class="text-capitalize font-weight-regular">
                                    Transfer Out Balance Applied 
                                </span>
                            </v-col>
                            <v-col
                                cols="6"
                                md="2"
                                class="pa-0 pb-1 pl-3 pr-6 text-right"
                            >
                                <span
                                    class="font-weight-regular"
                                    v-if="payment && payment.id"
                                    >{{
                                        transferOutBalanceApplied | formatMoney
                                    }}</span
                                >
                                <span class="font-weight-regular" v-else>{{
                                    calculateTotalDue | formatMoney
                                }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="10" class="pa-0 pb-1 pl-3 text-right">
                                <span class="text-capitalize font-weight-regular">
                                    Payment Amount   
                                </span>
                            </v-col>
                            <v-col
                                cols="6"
                                md="2"
                                class="pa-0 pb-1 pl-3 pr-6 text-right"
                            >
                                <span
                                    class="font-weight-regular"
                                    v-if="payment && payment.id"
                                    >{{ payment.amount | formatMoney }}</span
                                >
                                <span class="font-weight-regular" v-else>{{
                                    0 | formatMoney
                                }}</span>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="6" md="10" class="pa-0 pb-1 pl-3 text-right">
                                <span
                                    class="text-capitalize font-weight-regular"
                                    >Balance Remaining</span
                                >
                            </v-col>
                            <v-col
                                cols="6"
                                md="2"
                                class="pa-0 pb-1 pl-3 pr-6 text-right"
                            >
                                <span
                                    class="font-weight-regular"
                                    v-if="payment && payment.id"
                                    >{{ 0 | formatMoney }}</span
                                >
                                <span class="font-weight-regular" v-else>{{
                                    (calculateTotalDue -
                                        transferOutBalanceApplied)
                                        | formatMoney
                                }}</span>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script type="text/babel">
import Status from "../../includes/Messages.vue";
import Loader from "../../assets/CircleSpinner.vue";
import api from "../../../assets/api.js";
import { mapGetters } from "vuex";
export default {
    name: "ViewTransferReceipt",
    data() {
        return {
            taxRate: 0,
        };
    },
    created() {
        this.getTaxRate();
    },
    computed: {
        ...mapGetters({
            isAdmin: "authenticationStore/isAdmin",
        }),
        calculateTotalDue() {
            let total = [];
            Object.entries(this.invoices).forEach(([key, val]) => {
                total.push(val.total_due - val.total_discounts);
            });
            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },
        transferOutBalanceApplied() {
            let total = [];
            Object.entries(this.move_out_invoices).forEach(([key, val]) => {
                total.push(val.credit);
            });
            return total.reduce(function (total, num) {
                return total + num;
            }, 0);
        },
    },
    methods: {
        getTaxRate() {
            api.get(this, api.SETTINGS_BILLING).then((r) => {
                this.taxRate = r.settings.taxRate;
            });
        },
        getImageType(imgUrl){
            let imgArr = imgUrl.split('.');
            return imgArr[imgArr.length - 1];
        }
    },
    components: {
        Status,
        Loader,
    },
    props: ["contact", "move_out_invoices", "invoices", "payment", "transfer", "company", "property"],
};
</script>

<style scoped>
.hb-header-font-size-receipt {
    font-size: 30px;
  }
</style>
