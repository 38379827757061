<template>
    <div>
        <v-container no-gutters class="pr-0">
            <v-row no-gutters>
                <v-col md="5" no-gutters>
                    <div class="hb-font-header-2-medium pt-1">Merchandise</div>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="text-right pr-0 mr-0" md="5">
                    <hb-text-field
                        search
                        v-model="product_search"
                        placeholder="Search"
                    >
                    </hb-text-field>
                </v-col>
            </v-row>
        </v-container>

        <v-container class="insurance-container">
            <v-row v-for="product in filtered_products" :key="product.id" class="merchandise-row">
                <v-col md="6">
                    <strong>{{product.name}}</strong><br />
                    <p>{{product.description}}</p>
                </v-col>
                <v-spacer></v-spacer>
                <v-col class="mt-1" :class="{'text-right': true, 'pa-5': !isVariableRate(product.amount_type) }" md="2">
                    <v-text-field v-if="isVariableRate(product.amount_type)"
                        dense
                        v-model="product.price"
                        label="Price"
                        v-validate="'decimal:2|max_value:9999|min_value:0.01'"
                        data-vv-name="price"
                        :id="'price_' + product.id"
                        :name="'price_' + product.id"
                        outlined>
                    </v-text-field>
                    <span v-else>{{product.price | formatMoney}}</span>
                </v-col>
                <v-col class="text-right mt-1" md="2">
                    <v-text-field
                      dense
                      v-model="product.qty"
                      label="Qty"
                      v-validate="'numeric|min_value:1'"
                      data-vv-name="qty"
                      :id="'product_' + product.id"
                      :name="'product_' + product.id"
                      :error-messages="errors.collect('product_' + product.id)"
                      outlined>
                    </v-text-field>
                </v-col>
                <v-col class="text-right" style="margin-top:6px;" md="2">

                    <hb-btn @click="saveService(product)" v-if="services[product.id] && services[product.id].product_id" color="secondary">Update</hb-btn>
                    <hb-btn @click="saveService(product)" v-else color="primary">Add</hb-btn>
                    <hb-link class="remove-service-btn" @click="removeService(product)" v-if="services[product.id] && services[product.id].product_id">Remove</hb-link>
                </v-col>
            </v-row>

        </v-container>
    </div>
</template>

<script type="text/babel">

    import api from '../../../assets/api.js';
    import { notificationMixin } from  '../../../mixins/notificationMixin.js';

    import moment from 'moment';

    export default {
        name: "ConfigureMerchandise",
        mixins: [ notificationMixin ],
        data() {
            return {

                product_search: '',
            }
        },
        props: ['merchandise', 'services'],
        created (){

        },
        filters:{
            lineTotal(c){
                return c.qty * c.cost;
            },
            getEndOfPeriod(date){
                return moment(date).add(1, 'month').subtract(1, 'day');
            }
        },
        computed: {
            filtered_products: {
                get() { 
                    return this.merchandise.filter(p => !this.product_search || p.name.toLowerCase().search(this.product_search.toLowerCase()) >= 0 || (p.description && p.description.toLowerCase().search(this.product_search.toLowerCase()) >= 0) )
                }
            }
        },
        methods: {
            async saveService(product){
                let status =  await this.validate(this);

                if(!product.qty) {
                    this.showMessageNotification({ description: 'Please enter a valid qty' })
                    return;
                }else if(!product.price){
                    this.showMessageNotification({ description: 'Please enter a valid price' })
                    return;   
                };

                if(!status) return;
                var data = {
                    product_id: product.id,
                    name: product.name,
                    price: product.price,
                    qty: parseInt(product.qty),
                    recurring: 0,
                    type: product.type,
                    prorate: 0,
                    description: product.description,
                    start_date: moment().format('YYYY-MM-DD'),
                    end_date: moment().format('YYYY-MM-DD'),
                    taxable:product.taxable
                };


                this.$set(this.services, product.id, data);

                //this.$emit('setService', data);
            },
            removeService(product){
                product.qty = null;

                this.services[product.id] = {};
                this.$emit('services', this.services);
            },
            isVariableRate(rate_type) {
                return rate_type === 'variable';
            }
        }
    }
</script>

<style scoped>

    .insurance-container{
        border: 1px solid #DFE3E8;
        background: white;
        border-radius: 4px;
        padding: 12px 36px 0 36px;
    }

    .insurance-container .v-input--radio-group--column{
        margin: 0;
    }
    .insurance-container .insurance-row {
        margin-bottom: 10px;

    }
    .insurance-option-row.no-border{
        border-bottom: none;
    }
    .merchandise-row{
        border-bottom: 1px solid #DFE3E8;
    }
    .merchandise-row:last-child{
        border-bottom: none;
    }
    .remove-service-btn {
        display: block;
        padding-top: 10px;
    }

</style>
