<template>
  <v-card flat color="#F9FAFB" style="color: rgba(0, 0, 0, 0.87);">
    <v-card-title class="pl-7">Contact Summary</v-card-title>
    <v-card-text style="color: rgba(0, 0, 0, 0.87);" >

      <v-container>
        <v-row dense>
          <v-col>
            <div v-if="property.name">{{property.name}}</div>
            <div v-if="property.Address">{{property.Address.address}} {{property.Address.address2}}<br />
              {{ property.Address | formatAddress }}
            </div>
            <div v-if="property.Phones && property.Phones.length" v-for="(phone, i) in property.Phones" :key="i">
              {{phone.phone | formatPhone}} - {{phone.type}}
            </div>
          </v-col>
        </v-row>
      </v-container>
      <v-divider></v-divider>
      <v-container v-show="step > 1">
        <v-row dense>
          <v-col>
            <div v-if="contact.first && contact.last">{{contact.first}} {{contact.last}}</div>
            <div v-if="contact.email">{{contact.email}}</div>
            <div v-if="contact.Phones && contact.Phones.length">{{ contact.Phones[0].phone | formatPhone}}<br /></div>
          </v-col>
        </v-row>
      </v-container>
      <v-divider v-show="step > 1"></v-divider>

      <v-container>
        <v-row v-for="(service, i) in services" :key="i" dense>
          <v-col cols="8">{{service.name}}<span v-if="service.qty > 1" class="ml-1">Qty x {{service.qty}}</span></v-col>
          <v-col class="align-right"><span v-if="service.qty > 1">{{service.price * service.qty | formatMoney}}</span><span v-else>{{service.price | formatMoney}}</span></v-col>
        </v-row>
      </v-container>


      <v-container style="background-color: white;" v-if="invoice.total_due && step > 2">
        <v-row dense>
          <v-col >Subtotal</v-col>
          <v-col class="align-right">{{invoice.sub_total | formatMoney}}</v-col>
        </v-row>
        <v-row dense>
          <v-col >Tax</v-col>
          <v-col class="align-right">{{invoice.total_tax | formatMoney}}</v-col>
        </v-row>
        <v-divider></v-divider>
        <v-row dense>
          <v-col style="font-weight: 500;">Total</v-col>
          <v-col style="font-weight: 500;" class="align-right">{{invoice.total_due | formatMoney}}</v-col>
        </v-row>
      </v-container>

    </v-card-text>

  </v-card>


</template>


<script type="text/babel">
    import Status from '../../includes/Messages.vue';
    import Loader from '../../assets/CircleSpinner.vue';
    import api from '../../../assets/api.js';
    import moment from 'moment';

    export default{
        name: "MerchandiseSummaryPanel",
        data(){
            return {

            }
        },
        components:{
        },
        created(){

        },
        computed:{
        },
        methods:{

        },
        props:['contact', 'property', 'services', 'invoice', 'step'],
        watch:{
        }

    }


</script>
