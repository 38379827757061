<template>
    <div>
        <div class="services-section scrollable-content">
            <status
                @resetStatus="errorClear($options.name)"
                v-if="errorHas($options.name)"
                :message="errorGet($options.name)"
                status="error"
            ></status>
            <view-transfer-receipt
                :contact="contact"
                :move_out_invoices="move_out_invoices"
                :invoices="invoices"
                :payment="payment"
                :transfer="transfer"
                :company="company" 
                :property="property"
            ></view-transfer-receipt>
            <div class="new-tenant-walkthrough-footer">
                <v-row class="ml-0">
                    <v-col class="pt-4">
                        <help-juice />
                    </v-col>
                    <v-col class="d-flex align-center justify-end pr-sm-7">
                        <!-- <hb-link class="mr-2" @click="$emit('close')"
                            >Cancel</hb-link
                        > -->
                        <hb-btn
                            class="mr-2"
                            color="secondary"
                            :to="
                                '/reports.html?type=transfer_receipt&request_id='+ lease.id
                            "
                            target="_blank"
                            >Print / Download Receipt</hb-btn
                        >
                        <hb-btn
                            class="mr-2"
                            color="secondary"
                            @click="sendReceiptModal = true"
                            >Send Receipt</hb-btn>
                        <!-- <hb-btn
                            class="mr-2"
                            color="secondary"
                            @click="$emit('close')"
                            >Email</hb-btn
                        > -->
                        <hb-btn
                            color="primary"
                            @click="$emit('close')"
                            >Finish and Close</hb-btn
                        >
                    </v-col>
                </v-row>
            </div>
        </div>

        <hb-modal
            confirmation
            footerOff
            v-model="sendReceiptModal"
            size="medium"
            title="Send Receipt"
            title-icon="mdi-user-actions-custom-1"
            show-help-link
            >
            <template v-slot:content>
                <send-receipt :payment_id="payment.id" :contact="contact" @close="sendReceiptModal = false"></send-receipt>
            </template>
        </hb-modal>

    </div>
</template>

<script type="text/babel">
import api from "../../../assets/api.js";
import Status from "../../includes/Messages.vue";
import Loader from "../../assets/CircleSpinner.vue";
import moment from "moment";
import ViewTransferReceipt from "./ViewTransferReceipt.vue";
import SendReceipt from "../../assets/SendReceipt.vue";
import { mapGetters } from "vuex";
import { EventBus } from "../../../EventBus.js";
export default {
    name: "TransferReceipt",
    data() {
        return {
            payment: {},
            invoices: [],
            move_out_invoices: [],
            company: {},
            contact: {},
            property: {},
            transfer: {},
            sendReceiptModal: false,
        };
    },
    components: {
        Status,
        Loader,
        ViewTransferReceipt,
        SendReceipt
    },
    computed: {
        ...mapGetters({
            lease: "onBoardingStore/lease",
            payments: "onBoardingStore/payments",
            paymentMethod: "onBoardingStore/paymentMethod",
        }),
    },

    async created() {
        await this.fetchData();
    },
    methods: {
        async fetchData() {
            let r = await api.get(this, api.LEASES + this.lease.id + "/transfer-receipt");
            this.payment = r.payment;
            this.invoices = r.move_in_invoices;
            this.move_out_invoices = r.move_out_invoices;
            this.company = r.company;
            this.contact = r.contact;
            this.property = r.property;
            this.transfer = r.transfer;
        }
    },
};
</script>

<style scoped>
.insurance-container {
    border: 1px solid #C4CDD5;
    background: white;
    padding: 30px;
    border-radius: 4px;
    margin-bottom: 30px;
}
.policy-container {
    padding-top: 0;
    padding-bottom: 0;
}
.form-label {
    background: #F4F6F8;
    border-radius: 4px;
}
.insurance-option-row {
    border-bottom: 1px solid #C4CDD5;
    padding: 10px 20px;
}
.insurance-section-label {
    padding: 30px;
    font-weight: bold;
}
.insurance-container {
    padding: 30px 50px;
}

.insurance-container .v-input--radio-group--column {
    margin: 0;
}
.insurance-container .insurance-row {
    margin-bottom: 10px;
}
.insurance-option-row.no-border {
    border-bottom: none;
}

.new-tenant-walkthrough-footer {
    padding: 3px 0 3px 30px;
    background: #FFFFFF;
    border-top: 1px solid #e2e2e2;
    position: absolute;
    bottom: 0;
    width: 100%;
    z-index: 100;
    left: 0;
    right: 0;
}
.services-section {
    padding: 20px 50px 100px 50px;
}

.policy-container .v-input.v-input--selection-controls.v-input--checkbox {
    margin-top: 0;
}

.signatures .v-input--selection-controls .v-input__control {
    flex-grow: 1;
}

.send-block {
    margin-top: 10px;
}
</style>
