<template>
    <div class="section-content ma-0 pa-0 edit-template-config h-100">
        <template v-if="showTags">
            <DraggableSection @onCloseClick="onClose" @addMergeField="addMergeField" :h="200"
                :context="currentItem.isNew ? config.type : currentItem?.item?.type">
            </DraggableSection>
        </template>
        <v-row class="ma-0 main-section-content" style="background-color: #F9FAFB;">
            <v-col class="left-panel">
                <div class="left-preview">
                    <v-row no-gutters>
                        <v-col>
                            <span class="text-h6"> {{ currentItem.isNew ? 'Create Template' : 'Edit Template' }}</span>
                        </v-col>
                    </v-row>
                    <v-divider class="mt-5"></v-divider>
                </div>
                <v-expansion-panels v-model="templateSettings"
                    v-if="currentItem?.item?.type === 'email' || 'sms' || currentItem.isNew">
                    <hb-expansion-panel :top-margin="true">
                        <template v-slot:title>
                            Template Settings
                        </template>
                        <template v-slot:content>
                            <hb-form label="Template Name"
                                description="How this template will be listed in template list" full required>
                                <HbTextField v-model="config.name" v-validate="'required|max:25'" data-vv-name="name"
                                    data-vv-scope="general" data-vv-as="Template Name"
                                    :error="errors.has('general.name')" placeholder="Template Name" class="mb-2" />
                            </hb-form>
                            <hb-form label="Template Type" description="Select the category of the template" full
                                required>
                                <HbSelect v-model="config.category" :items="items" v-validate="{ required: true }"
                                    data-vv-scope="general" data-vv-name="category" data-vv-as="Template Type"
                                    :error="errors.has('general.category')" placeholder="Select Category"
                                    class="mb-3" />
                            </hb-form>
                            <hb-form label="Delivery Type" full required description="Select the Delivery Type">
                                <HbSelect v-model="config.type" :items="itemsTypes" v-validate="{ required: true }"
                                    data-vv-scope="general" data-vv-name="type" data-vv-as="Message Type"
                                    :disabled="!currentItem.isNew" :error="errors.has('general.type')"
                                    placeholder="Select Delivery Type" class="mb-3" />
                                <p v-if="config.type == 'email'">Subject Line:</p>
                                <HbTextField v-model="config.email_subject" v-if="config.type == 'email'"
                                    v-validate="{ required: true }" id="messageSubject" class="mb-3 mt-2"
                                    name="messageSubject" data-vv-scope="general" data-vv-as="MessageSubject"
                                    :error="errors.has('general.messageSubject')" placeholder="Email Subject" />
                            </hb-form>
                            <hb-form label="Enable Properties" full>
                                <hb-combobox style="max-width: 720px !important;" v-model="config.properties"
                                    v-validate="{ required: true }" editable :items="filterPropertyList"
                                    data-vv-name="property" data-vv-as="properties" item-text="name"
                                    label="Select properties" :disabled="checkIfCreate"
                                    :error="errors.has('general.property')" />
                            </hb-form>
                        </template>
                    </hb-expansion-panel>
                </v-expansion-panels>
                <div v-if="currentItem.isNew">
                    <div v-if="currentItem?.item?.type === 'email' || config?.type == 'email'">
                        <div class="content-wrapper-rich-text">
                            <div>
                                <template
                                    v-if="currentItem?.item?.is_base_template == 1 || currentItem?.item?.base_template_id != null">
                                    <editor v-model="content" :api-key="tinymceApiKey" :init="tinymceConfig" />
                                </template>
                                <template v-else>
                                    <rich-text-editor class="email-rich-editor" v-model="content" proseHeight="450px" />
                                </template>
                            </div>

                            <div class="ma-0 pa-2">

                                <div class="hb-bab-wrapper d-flex align-center">
                                    <div class="hb-bab-left-actions-wrapper">
                                        <div class="d-flex bottom-action">
                                            <hb-btn color="secondary" @click="showTags = !showTags">Add Merge
                                                Fields</hb-btn>
                                            <hb-tooltip>
                                                <template v-slot:body>
                                                    Merge fields are placeholders in templates that are <br /> replaced
                                                    with
                                                    data when the template is used
                                                </template>
                                            </hb-tooltip>
                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex align-center pr-0 hb-bab-right-actions-wrapper">
                                        <span class="hb-bab-right-actions-wrapper-span">
                                            <hb-btn color="secondary" @click="previewContent()">Preview</hb-btn>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-else>
                    <div v-if="currentItem?.item?.type === 'email'" class="mb-10">
                        <div class="content-wrapper-rich-text">
                            <div>
                                <template
                                    v-if="currentItem?.item?.is_base_template == 1 || currentItem?.item?.base_template_id != null">
                                    <editor v-model="content" :api-key="tinymceApiKey" :init="tinymceConfig" />
                                </template>
                                <template v-else>
                                    <rich-text-editor class="email-rich-editor" v-model="content" proseHeight="400px" />
                                </template>
                            </div>
                            <div class="ma-0 pa-2">

                                <div class="hb-bab-wrapper d-flex align-center">
                                    <div class="hb-bab-left-actions-wrapper">
                                        <div class="d-flex bottom-action">
                                            <hb-btn color="secondary" @click="showTags = !showTags">Add Merge
                                                Fields</hb-btn>
                                            <hb-tooltip>
                                                <template v-slot:body>
                                                    Merge fields are placeholders in templates that are <br /> replaced
                                                    with
                                                    data when the template is used
                                                </template>
                                            </hb-tooltip>
                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex align-center pr-0 hb-bab-right-actions-wrapper">
                                        <span class="hb-bab-right-actions-wrapper-span">
                                            <hb-btn color="secondary" @click="previewContent()">Preview</hb-btn>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                <div v-if="currentItem.isNew">
                    <v-expansion-panels v-model="panelSms"
                        v-if="currentItem?.item?.type == 'sms' || config?.type == 'sms'" multiple>
                        <hb-expansion-panel :top-margin="true">

                            <template v-slot:title>
                                Content
                            </template>

                            <template v-slot:content>
                                <div class="mt-3 mb-3 ml-3">
                                    <!-- <rich-text-editor class="email-rich-editor" v-model="smsMessage" proseHeight="300px"/> -->
                                    <v-textarea v-model="smsMessage" hide-details no-resize></v-textarea>
                                </div>
                            </template>

                            <template v-slot:footer>
                                <hb-bottom-action-bar cancel-off>
                                    <template v-slot:left-actions>
                                        <div class="d-flex bottom-action">
                                            <hb-btn color="secondary" @click="showTags = !showTags">Add Merge
                                                Fields</hb-btn>
                                            <hb-tooltip>
                                                <template v-slot:body>
                                                    Merge fields are placeholders in templates that are <br /> replaced
                                                    with
                                                    data when the template is used
                                                </template>
                                            </hb-tooltip>
                                        </div>
                                    </template>

                                    <template v-slot:right-actions>
                                        <hb-btn color="secondary" @click="previewSMS()">Preview</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                            <div class="ma-0 pa-2">

                                <div class="hb-bab-wrapper d-flex align-center">
                                    <div class="hb-bab-left-actions-wrapper">
                                        <div class="d-flex bottom-action">
                                            <hb-btn color="secondary" @click="showTags = !showTags">Add Merge
                                                Fields</hb-btn>
                                            <hb-tooltip>
                                                <template v-slot:body>
                                                    Merge fields are placeholders in templates that are <br /> replaced
                                                    with
                                                    data when the template is used
                                                </template>
                                            </hb-tooltip>
                                        </div>
                                    </div>
                                    <v-spacer></v-spacer>
                                    <div class="d-flex align-center pr-0 hb-bab-right-actions-wrapper">
                                        <span class="hb-bab-right-actions-wrapper-span">
                                            <hb-btn color="secondary" @click="previewSMS()">Preview</hb-btn>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </hb-expansion-panel>
                    </v-expansion-panels>
                </div>
                <div v-else>
                    <v-expansion-panels v-model="panelSms" v-if="currentItem?.item?.type == 'sms'" multiple>
                        <hb-expansion-panel :top-margin="true">

                            <template v-slot:title>
                                Content
                            </template>

                            <template v-slot:content>
                                <div class="mt-3 mb-3 ml-3">
                                    <v-textarea v-model="smsMessage" hide-details no-resize></v-textarea>
                                </div>
                            </template>

                            <template v-slot:footer>
                                <hb-bottom-action-bar cancel-off>
                                    <template v-slot:left-actions>
                                        <div class="d-flex bottom-action">
                                            <hb-btn color="secondary" @click="showTags = !showTags">Add Merge
                                                Fields</hb-btn>
                                            <hb-tooltip>
                                                <template v-slot:body>
                                                    Merge fields are placeholders in templates that are <br /> replaced
                                                    with
                                                    data when the template is used
                                                </template>
                                            </hb-tooltip>
                                        </div>
                                    </template>

                                    <template v-slot:right-actions>
                                        <hb-btn color="secondary" @click="previewSMS()">Preview</hb-btn>
                                    </template>
                                </hb-bottom-action-bar>
                            </template>
                        </hb-expansion-panel>
                    </v-expansion-panels>
                </div>

            </v-col>
            <v-col class="right-panel">
                <div class="right-preview">
                    <v-row no-gutters>
                        <v-col>
                            <span class="text-h6"> Preview Template</span>
                        </v-col>
                        <v-col class="align-right">
                            <hb-tabs v-model="responsiveTab" right>
                                <v-tab :ripple="false" key="1">
                                    Desktop
                                </v-tab>
                                <v-tab :ripple="false" key="2">
                                    Mobile
                                </v-tab>
                            </hb-tabs>
                        </v-col>
                    </v-row>
                    <v-divider></v-divider>
                    <div v-if="config?.type === 'email' || currentItem?.item?.type === 'email'"
                        :class="{ 'hb-elevation-small': filteredMail && filteredMail.trim() !== '' && filteredMail.trim() !== '<p></p>' }"
                        class="mt-3 mx-auto d-flex flex-column" :style="dynamicStyle">
                        <div class="pa-6 tm-content" style="display: block;" v-html="filteredMail"
                            v-if="filteredMail && filteredMail.trim() !== '' && filteredMail.trim() !== '<p></p>'">
                        </div>
                        <HbEmptyState v-else message="No preview has been created yet." />
                    </div>
                    <div v-else>
                        <div v-html="sms" class="pa-5 mt-5 mx-auto tm-content hb-elevation-small"
                            style="width: 400px" v-if="sms && sms.trim() !== ''">
                        </div>
                        <HbEmptyState v-else message="No preview has been created yet." class="mt-3" />
                    </div>
                </div>

            </v-col>
        </v-row>
    </div>
</template>

<script type="text/babel">
import { mapGetters } from "vuex";
import api from '../../../../assets/api.js';
import TabSection from '../../../includes/Tabs/Index.vue';
import RichTextEditor from "../../../assets/RichTextEditor.vue";
import { notificationMixin } from '../../../../mixins/notificationMixin.js';
import templateConfig from './templateConfig.vue'
const DraggableSection = () => import('./DraggableSection.vue');
import { EventBus } from "../../../../EventBus.js";
import Editor from '@tinymce/tinymce-vue'
export default {
    name: "edit-preview",
    expose: ['getData', 'getConfigData'],
    mixins: [notificationMixin],
    props: ['currentItem'],
    data() {
        return {
            originalConfig:{},
            config: {
                name: '',
                category: 'basic',
                type: '',
                email_subject: '',
                properties: [],
                properties_backup: [],
            },
            itemsTypes: [
                { text: 'SMS', value: 'sms' },
                { text: 'Email', value: 'email' },
            ],
             // TODO : its should be in backend
            items: [
                { text: 'Rent Change', value: 'rent_change' },
                { text: 'Delinquency', value: 'deliquency' },
                { text: 'Maintenance', value: 'maintenance' },
                { text: 'Alerts', value: 'alerts' },
                { text: 'General', value: 'general' },
            ],
            templateSettings: 0,
            Panel: [0, 1],
            panelSms: [0],
            responsiveTab: 0,
            header: {
                content: ``,
                toogle: true,
            },
            footer: {
                content: "",
                toogle: true
            },
            originalContent: ``,
            originalSmsContent:``,
            content: ``,
            smsMessage: ``,
            sms: '', // only to render
            mail: '', // only to render
            html: ``,
            loading: false,
            showTags: false,
            tinymceConfig: {
                image_title: true,
                automatic_uploads: true,
                file_picker_types: 'image',
                file_picker_callback: (cb, value, meta) => {
                    const input = document.createElement('input');
                    input.setAttribute('type', 'file');
                    input.setAttribute('accept', 'image/*');

                    input.addEventListener('change', (e) => {
                        const file = e.target.files[0];

                        const reader = new FileReader();
                        reader.addEventListener('load', () => {
                            const id = 'blobid' + (new Date()).getTime();
                            const blobCache = tinymce.activeEditor.editorUpload.blobCache;
                            const base64 = reader.result.split(',')[1];
                            const blobInfo = blobCache.create(id, file, base64);
                            blobCache.add(blobInfo);

                            /* call the callback and populate the Title field with the file name */
                            cb(blobInfo.blobUri(), { title: file.name });
                        });
                        reader.readAsDataURL(file);
                    });
                    input.click();
                },
                height: 500,
                branding: false,
                elementpath: false,
                element_format: 'html',
                editable_class: 'mceEditable',
                custom_elements: "style, head , meta, html , title",
                // protect: [
                //     /\{%\s*endif\s*%\}/g,
                //     /\{%\s*else\s*%\}/g,
                //     /\{%\s*if\s+[^}]+\s*%\}/g,
                // ],
                object_resizing: 'img',
                table_sizing_mode: 'responsive',
                invalid_elements: 'script',
                menu: {
                    file: { title: 'File', items: 'preview' },
                },
                menubar: 'file edit insert format table',
                plugins: [
                    'autolink fullpage lists link image anchor preview',
                    'code',
                    'insertdatetime media table paste code wordcount noneditable importcss'
                ],
                toolbar: 'preview | image code undo redo | formatselect | bold italic backcolor | link | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
            },
            tinymceApiKey: process.env.VUE_APP_TINYMCE_FREE_API_KEY,
        };
    },
    components: {
        TabSection,
        RichTextEditor,
        DraggableSection,
        'editor': Editor,
        templateConfig,
    },
    computed: {
        ...mapGetters({
            facilityList: 'propertiesStore/filtered',
        }),
        filteredMail: function () {
            // Remove all content inside {% ... %} from mail
            if (this.currentItem.item) return this.mail.replace(/{%(.*?)%}/g, "");
            else return this.mail
        },
        checkIfCreate() {
            if (!this.currentItem) {
                // If currentItem is not present(new create)
                return false;
            }
            else {
                // If this.currentItem exists, check if its propertyId is not an empty string
                if (!this.currentItem?.propertyId) {
                    // If propertyId is an empty string
                    return false
                } else {
                    // If propertyId is not an empty string
                    return true
                }
            }
        },

        filterPropertyList() {
            return this.facilityList.map(obj => ({ id: obj.id, name: obj.name }));
        },

        shouldShowRichEditor() {
            const checkIfCreate = this.checkIfCreate;
            const currentItem = this.currentItem; // Assuming currentItem is available in your component

            return checkIfCreate || !currentItem?.item?.is_base_template || !currentItem?.item?.base_template_id || currentItem?.item?.base_template_id !== null;
        },

        dynamicStyle() {
            if (this.responsiveTab === 0) {
                return 'width:700px'
            } else {
                return 'width:479px'
            }

        },
        checkIfCreate() {
            if (this.currentItem) {
                return false
            }
            else return true
        },
    },
    watch: {
        content: {
            handler(newVal) {
                const isSame = newVal === this.originalContent;
                this.$emit('content-changed', !isSame);
            },
        },
        config :{
            handler(newVal) {
                const isSame = JSON.stringify(newVal) === JSON.stringify(this.originalConfig);
                this.$emit('content-changed', !isSame);
            },
            deep: true
        },
        smsMessage: {
            handler(newVal) {
                const isSame = newVal === this.originalSmsContent;
                this.$emit('content-changed', !isSame);
            },
        }
    },
    mounted() {
        this.loaddata()
        this.originalContent = this.content;
        this.originalSmsContent = this.smsMessage
        this.originalConfig = JSON.parse(JSON.stringify(this.config));
    },
    methods: {
        async addMergeField(mytext, context) {
            if (context == 'email') EventBus.$emit("HB:templatedata", mytext);
            else this.smsMessage = (this.smsMessage || '') + mytext;
        },
        onClose() {
            this.showTags = false
        },

        loaddata() {
            this.config.email_subject = this.currentItem?.item?.template.subject
            this.content = this.currentItem?.item?.template.body ? this.currentItem?.item?.template.body : this.currentItem?.item?.template.content
            this.mail = this.currentItem?.item?.template.body ? this.currentItem?.item?.template.body : this.currentItem?.item?.template.content
            this.smsMessage = this.currentItem?.item?.template?.smsContent
            if (this.smsMessage) this.sms = this.smsMessage


            if (this.currentItem?.duplicate == true) {
                this.config.name = ""
            }
            else {
                this.config.name = this.currentItem?.item?.name
            }
            this.config.category = this.currentItem?.item?.category
            this.config.type = this.currentItem?.item?.type
            if (this.currentItem?.propertyId) {
                const property = this.facilityList.find(item => item.id === this.currentItem?.propertyId);
                this.config.properties.push(property);
            }
            else {
                const propertyIds = this.currentItem?.item?.propertyIds; // geting from db
                propertyIds?.forEach(propertyId => {
                    // Find the property with the matching ID
                    const property = this.facilityList.find(item => item.id === propertyId);

                    // If property found, append its name to this.config.properties
                    if (property) {
                        this.config.properties.push(property);
                    } else {
                        // If property not found in facilityList, push its ID to config.properties
                        this.config.properties_backup.push({ id: propertyId });
                    }
                });
            }
        },
        getConfigData() {
            // Return the form data
            const { name, category, type, properties, properties_backup } = this.config;

            let data = {
                name,
                category,
                type,
                properties,
            };

            if (properties && properties.length > 0) {
                data.properties = properties.map(property => property.id);
            }

            if (properties_backup && properties_backup.length > 0) {
                if (!data.properties) {
                    data.properties = [];
                }
                data.properties = data.properties.concat(properties_backup.map(property => property.id));
            }
            return data;
        },
        previewSMS() {
            this.templateSettings = 1
            this.$emit('scrollIntoView', true);
            this.sms = this.smsMessage;
        },
        previewContent() {
            this.templateSettings = 1
            this.$emit('scrollIntoView', true);
            this.mail = this.content
        },
        addOneTimePaymentLink() {

        },
        handleItemClick(index) {

            if (index == 0) {
                this.content += `<button style="background: linear-gradient(180deg, #47C0BF -70%, #00848E 126.25%);">View Account</button>`;
            }
        },
        getData() {
            // Return the form data
            let data = {}
            if (this.config?.type === 'sms' || this.currentItem?.item?.type === 'sms') {
                data = {
                    smsContent: this.smsMessage,
                }
            }
            if (this.config?.type == 'email' || this.currentItem?.item?.type === 'email') {
                data = {
                    subject: this.config.email_subject,
                    body: this.content,
                }
            }
            return data;
        },

    },
}

</script>

<style scoped>
.left-panel {
    width: 50% !important;
    margin-bottom: 15px;
    padding-left: 23px;
}

.right-panel {
    width: 50% !important;
}

/* .right-panel .right-preview {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
} */

/* .left-panel .left-preview {
    position: -webkit-sticky;
    position: sticky;
    top: 80px;
    z-index: 9999;
} */

.tm-content /deep/ p {
    margin: 0px;
}

.tm-content /deep/ p:empty {
    /* to show empty p tag */
    min-height: 1em;
}

.email-rich-editor {
    padding: 0 !important;
}

.content-wrapper-rich-text {
    margin-top: 12px;
    background: white;
    padding: 9px 7px 0px 7px;
    border: 1px solid rgb(235, 235, 235) !important;
}

.content-wrapper-rich-text .email-rich-editor> :first-child {
    padding: 10px !important;
    border-bottom: 1px solid rgb(235, 235, 235) !important;
    background-color: #F9FAFB !important;
}

.content-wrapper-rich-text .email-rich-editor> :nth-child(2) {
    border-bottom: 1px solid rgb(235, 235, 235) !important;
    border-top: 0px solid rgb(235, 235, 235) !important;
    border-right: 0px solid rgb(235, 235, 235) !important;
    border-left: 0px solid rgb(235, 235, 235) !important;
}

.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child {
    overflow-y: auto !important;
}

.bottom-action> :nth-child(3) {
    margin-left: 7px;
    margin-top: 5px;
}

.content-wrapper-rich-text .email-rich-editor> :first-child>>>span:nth-child(n+6) {
    margin-left: 12px !important;
}

/* width */
.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child ::-webkit-scrollbar {
    width: 10px;
}

/* Track */
.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child ::-webkit-scrollbar-track {
    background: #f1f1f1;
}

/* Handle */
.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child ::-webkit-scrollbar-thumb {
    background: #afabab;
    cursor: default;
}

/* Handle on hover */
.content-wrapper-rich-text .email-rich-editor> :nth-child(2) :first-child ::-webkit-scrollbar-thumb:hover {
    background: #838181;
}

.hb-bab-left-actions-wrapper>>>a:nth-of-type(1) {
    padding-left: 8px;
}

.hb-bab-right-actions-wrapper .hb-bab-right-actions-wrapper-span>>>button,
.hb-bab-right-actions-wrapper .hb-bab-right-actions-wrapper-span>>>a {
    margin-left: 12px;
}

.hb-bab-border-bottom-radius {
    border-bottom-left-radius: 4px !important;
    border-bottom-right-radius: 4px !important;
}

.bg-white {
    background-color: #FFFFFF;
}
</style>