<template>
  <div class="intent-section px-7">
    <div class="hb-font-header-2-medium mt-n1 mb-4">Move-Out Statement</div>

    <hb-card
      v-if="(leaseInfo || (currentRefund && currentRefund.credit)) && ! contact?.national_account_id"
      border
      no-title
      :box-shadow="(leaseInfo && leaseInfo.promotion) || (currentRefund && currentRefund.credit && currentRefund.credit > 0) ? true : false"
    >
      <div style="padding: 10px 16px">
        <div class="hb-font-header-3-medium pb-1" v-if="leaseInfo && leaseInfo.promotion">
          Move-In Promotion: {{leaseInfo.promotion}}
        </div>
        <div class="hb-font-header-3-medium pb-1">
          Enrolled in AutoPay: {{ leaseInfo.has_autopay ? 'Yes' : 'No' }}
        </div>
        <div class="hb-font-header-3-medium danger-color" v-if="currentRefund && currentRefund.credit && currentRefund.credit > 0">
          Closing Statement Balance: Refund {{currentRefund.credit}}
        </div>
      </div>
    </hb-card>

    <!-- <buttons/> -->
    <v-col class="px-0 pt-0">
      <v-expansion-panels v-model="panels">
        
        <hb-expansion-panel success key="auction" v-if="lease.auction_status == 'move_out'" title-icon="mdi-gavel">
          <template v-slot:title>
            Sold at Auction
          </template>
          <template v-slot:content>
            <auction-sold :auctionLease="lease"></auction-sold>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel error key="incomplete-prep" v-if="!readyToMove && products && products.length">
          <template v-slot:title>
            Incomplete Prep For Rental
          </template>
          <template v-slot:content>
            <div class="pb-3 px-6">
              <incomplete-prep 
                :lease_id="lease_id"
                :property_id="property_id"
                :lease="lease"
                :contact="contact"
                @openBalance="setOpenBalance"
              />
            </div>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel error key="open-balance" v-if="(lease.auction_status !== 'move_out') && totalDue && totalDue > 0" v-show="!contact?.national_account_id">
          <template v-slot:title>
            <span class="mt-1">
              Open Balance:
            </span>    
            <span class="ml-1 hb-font-body font-weight-regular">{{totalDue && totalDue > 0 ? '$' + totalDue : '' }}</span>
          </template>
          <template v-slot:content>
            <div class="pb-3 px-6">
              <Payments 
                  :lease_id="lease_id"
                  :unit_id="unit_id"
                  :property_id="property_id"
                  :contactId="contact_id"
                  :payment_amount="totalDue"
                  :move_out_date="move_out_date"
                  @openBalance="setOpenBalance"
              />
            </div>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel caution key="security-deposit-refund" v-if="securityDeposit && securityDeposit > 0">
          <template v-slot:title>
            <span class="mt-1">
              Security Deposit:
            </span>
            <span class="ml-1 hb-font-body font-weight-regular">{{ securityDeposit | formatMoney }}</span>
          </template>
          <template v-slot:content>
            <div class="pb-3 px-6">
              <security-deposit-refund
                @fetchSecurityDeposit="fetchSecurityDeposit"
                :lease_id="lease_id"
                :security_deposit="securityDeposit"
                @securityRefunded="securityRefunded"
              />
            </div>
          </template>
        </hb-expansion-panel>
        <hb-expansion-panel error key="refund-request" :readonly="!isSecurityRefunded && securityDeposit && securityDeposit > 0 ? true : false" :expand-icon-off="!isSecurityRefunded && securityDeposit && securityDeposit > 0 ? true : false" v-if="amt_to_refund > 0">
          <template v-slot:title>
            <span class="mt-1">
              Refund Request:
            </span>
            <span class="ml-1 hb-font-body font-weight-regular">{{ amt_to_refund | formatMoney }}</span>
          </template>
          <template v-slot:actions>
            <v-tooltip bottom v-if="!isSecurityRefunded && securityDeposit && securityDeposit > 0">
              <template v-slot:activator="{ on, attrs }">
                <span v-bind="attrs" v-on="on"><hb-icon small color="#00000087" mdi-code="mdi-alert-circle"></hb-icon></span>
              </template>
              <span>Please process the refund of the security deposit first.</span>
            </v-tooltip>
          </template>
          <template v-slot:content>
            <div class="pb-3 px-6">
              <refund-request
                :account_balance="account_balance"
                :amt_to_refund="amt_to_refund"
                :overpayments="overpayments"
                :move_out_date="move_out_date"
                @fetchPayments="fetchOverPayments"
                :lease_id="lease_id"
                :current_refund="amt_to_refund"
                :contact_id="contact_id"
              />
            </div>
          </template>
        </hb-expansion-panel>

        <hb-expansion-panel key="move-out-notes" hide-actions-when-closed>
          <template v-slot:title>
            Move-Out Notes
          </template>
          <template v-slot:actions>
            <hb-btn small color="secondary" @click.native.stop="showNoteModal =! showNoteModal" class="pr-2">Add Note</hb-btn>
          </template>
          <template v-slot:content>
            <notes :show-note-modal="showNoteModal" :contact_id="contact_id" :unit_number="spaceNumber" :lease_id="lease_id" @close="closeNotesModal" />
          </template>
        </hb-expansion-panel>

       
      </v-expansion-panels>
    </v-col>

    <div class="new-tenant-walkthrough-footer">
      <v-row>
        <v-col class="pt-4">
          <help-juice />
        </v-col>
        <v-col cols="9" class="d-flex align-center justify-end pr-sm-7">
          <hb-link class="mr-2" @click="$emit('close')">Cancel</hb-link>
          <hb-btn class="mr-2"  color="primary" @click="closeLease" :loading="closeLeaseInProgress" :disabled="closeLeaseDisabled || leaseClosed || isLoading || closeLeaseInProgress || finalizeInProgress">
            Move Out
          </hb-btn>
          <!-- <hb-btn class="mr-2" color="primary" @click="moveSpaceBackToInventory" :disabled="moveSpaceToInventory">
            Move Space back to Inventory
          </hb-btn> -->
        </v-col>
      </v-row>
    </div>
  </div>
</template>
<script type="text/babel">
  import MaintenanceScheduled from "./MaintenanceScheduled.vue";
  import AuctionSold from "./AuctionSold.vue";
  import IncompletePrep from "./IncompletePrep.vue";
  import RefundRequest from "./RefundRequest.vue";
  import SecurityDepositRefund from "./SecurityDepositRefund.vue";
  import Buttons from "./Buttons.vue";
  import Notes from "./Notes.vue";
  import ActivityLogs from "./ActivityLogs.vue";
  import Payments from "./Payments.vue";
  import api from '../../../../assets/api.js';
  import moment from 'moment';
  import { EventBus } from '../../../../EventBus.js';
  import { mapGetters, mapActions, mapMutations } from 'vuex'; 
  import { notificationMixin } from  '../../../../mixins/notificationMixin.js';

export default {
  name: "MoveOutStatementIndex",
  components: {
    MaintenanceScheduled,
    AuctionSold,
    IncompletePrep,
    RefundRequest,
    SecurityDepositRefund,
    Buttons,
    Notes,
    ActivityLogs,
    Payments
  },
  mixins: [ notificationMixin ],
  data: () => ({
    amt_to_refund: 0,
    amount: 0,
    totalActivities: 0,
    account_balance: 0,
    overpayments: 0,
    securityDeposit: 0,
    refundInvoices:[],
    showNoteModal: false,
    leaseInfo: null,
    leaseClosed: false,
    move_in_ready: 1,
    products: [],
    leases: [],
    panels: [ 0 ],
    isLoading: false,
    finalizeInProgress: false,
    closeLeaseInProgress: false,
    isSecurityRefunded: false
  }),
  props: ['close', 'contact_id', 'lease', 'contact', 'property_id','lease_id', 'unit_id', "move_out_date", "ready_to_move_in"],
  async created() {
    this.isLoading = true
    this.$emit('showLeaseSummary');
    this.fetchOverPayments();
    this.getLeaseInfo();
    this.getFees();
    await this.fetchSecurityDeposit();
    await this.setContactInvoices();
    if(this.lease.end_date && this.lease.end_date >= moment().startOf('day').format('YYYY-MM-DD')){
      this.leaseClosed = true;
    }
    this.isLoading = false
  },
  computed: {
      ...mapGetters({
        notification: "charmCallStore/notification",
        charmNotification: "charmNotificationStore/charmNotification",
      }),
    currentRefund(){
        return this.refundInvoices.find(x => x.credit > 0);
    },
    spaceNumber(){
      return this.leases[0]?.Unit?.number
    },
    readyToMove() {
      return this.$props.ready_to_move_in;
    },
    moveSpaceToInventory() {
      let current_date = moment().startOf('day').format('YYYY-MM-DD')
      if(!this.$props.lease.end_date || this.$props.lease.end_date > current_date || this.$props.lease?.Unit?.status == 1) {
        return true
      }
      else {
        return false;
      }
    },
    closeLeaseDisabled() {
      return this.$props.lease.move_out ? true : false;
    },
    totalDue(){
      // lease_total is Open Balance : Sum of balances of all unpaid invoices of a lease having due date <= move_out_date
      let lease_total = this.leases.reduce((total, l) => total + (l.OpenInvoices.filter(oi => oi.due <= this.move_out_date).reduce((total_balance, oi) => total_balance + oi.balance, 0)), 0);
      let new_charges = this.leases.reduce((a,b) => a + (b.Charges && b.Charges.balance ? b.Charges.balance : 0), 0);
      return Math.round((lease_total + new_charges) * 1e2) / 1e2;
    },
  },
  methods: {
    ...mapMutations({
      resetContact: 'onBoardingStore/resetContact',
    }),
    ...mapActions({
      resetPropertiesInfo: 'propertiesStore/resetPropertiesInfo',
      fetchPropertyGroups: 'propertiesStore/fetchPropertyGroups'
    }),
    async setContactInvoices() {
      let r = await api.get(this, api.CONTACTS +  this.contact_id + '/leases', { property_id: this.property_id, active_date: moment.utc().format('YYYY-MM-DD') });
      this.leases = r.leases.filter(x=> x.id === this.lease_id);
      this.$emit('fetchLeaseData');
    },
    setOpenBalance() {
      this.setContactInvoices();
    },
    setActivitesCounter(value) {
      this.totalActivities = value;
    },
    async fetchOverPayments() {
      var date = moment(this.move_out_date).startOf("day").format("YYYY-MM-DD");
      let r = await api.get(this, api.LEASES + this.lease_id + '/over-payments', {moved_out: date})
      this.account_balance = r.account_balance;
      this.amt_to_refund = r.amt_to_refund;
      this.overpayments = r.overpayments;
      
      // this.refundInvoices = r.invoices.map(inv => {

      //   inv.credit =  inv.InvoiceLines.reduce((val, line ) => val + line.Credit.total,0) - 
      //                 (inv.balance + inv.Payments.filter(x => x.Payment.credit_type !== 'payment').reduce((a,p) =>  a + p.amount, 0));
                      
      //   inv.credit = Math.round(inv.credit * 1e2) / 1e2;

      //   inv.processed = 0;
      //   inv.handle_credit = '';
      //   inv.credit_memo = inv.credit > 0 ? 1: 0;

      //   inv.Payments = inv.Payments.map(p => {
      //       p.credit = 0;
      //       return p;
      //   })
      //   return inv;
      // });
    },
    async fetchSecurityDeposit() {
      await api.get(this, api.LEASES + this.lease_id + '/security-deposit').then(r => {
        this.securityDeposit = r.lines.reduce((a,b) => a += b.amount, 0);
      });
    },
    securityRefunded(flag){
      if(flag && this.securityDeposit == 0){
        this.isSecurityRefunded = true;
      }
    },
    getLeaseInfo(){
      var date = moment(this.move_out_date).startOf("day").format("YYYY-MM-DD");
      api.get(this, api.LEASES + this.lease_id + '/metrics', {moved_out: date}).then(r => {
        this.leaseInfo = r.metrics
      })
    },
    refreshContactUnits(){
      if(this.lease.Tenants && this.lease.Tenants.length > 0 && this.lease.Tenants[0].contact_id == this.charmNotification.contact_id){
          this.$store.dispatch('charmNotificationStore/refreshContactUnits',this.charmNotification.contact_id);
      }
      if(this.lease.Tenants && this.lease.Tenants.length > 0 && this.lease.Tenants[0].contact_id == this.notification.contact_id){
          this.$store.dispatch('charmCallStore/refreshContactUnits',this.notification.contact_id);
      }
    },
    async closeLease() {
      try {
        this.closeLeaseInProgress = true;
        var moved_out = moment(this.move_out_date).startOf('day').format('YYYY-MM-DD');
        var data = {
          moved_out: moved_out,
          availability_date: this.move_in_ready ? moved_out : moment(this.data.availability_date).startOf('day').format('YYYY-MM-DD'),
          readyToMove: this.readyToMove
        };

        this.resetContact();

        const response = await api.put(this, api.LEASES + this.lease_id + '/close', data);
        this.refreshContactUnits();

        if (response.lease_id) {
          this.leaseClosed = true;
          this.closeLeaseConfirmation = false;
          EventBus.$emit('lease_edited');
          this.$emit('fetchLeaseData');
          EventBus.$emit('contact_updated', this.contact_id);
          EventBus.$emit('contact_updated_transfer');
          EventBus.$emit('reservation_made');
        }

        if (this.readyToMove) {
          await this.moveSpaceBackToInventory();
        } else {
          this.$emit('close');
        }

        await this.resetPropertiesInfo();
        this.fetchPropertyGroups({hardRefresh: true});
      } catch (e) {
        this.showMessageNotification({ type: 'error', description: e });
      } finally {
        this.closeLeaseInProgress = false;
      }
    },
    async moveSpaceBackToInventory() {
      this.finalizeInProgress = true;
      try {
        await api.put(this, api.UNITS + this.unit_id + '/enable');
        this.$emit('fetchLeaseData');
        this.$emit('close');
      } catch (err) {
        this.showMessageNotification({ type: 'error', description: err });
      } finally {
        this.finalizeInProgress = false;
      }
    },
    getFees(){
        api.get(this, api.PROPERTIES  + this.property_id + '/products?type=late&category_type=moveout' ).then(r => {
            this.products = r.products;
        });
    },
    closeNotesModal(payload){
      this.showNoteModal = payload; 
    }
  }
};
</script>

<style scoped lang="scss">
.intent-section {
  padding: 20px 50px 75px 50px;
}

.intent-section .new-tenant-walkthrough-footer {
  padding: 3px 0 3px 30px;
  background: #ffffff;
  border-top: 1px solid #e2e2e2;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 100;
  left: 0;
  right: 0;
}
.light-text {
  color: rgba(0, 0, 0, 0.6);
}
.panel-header-custom-setting {
  background-color: rgb(251, 228, 224);
  color: rgba(0, 0, 0, 1);
}
.move-out-btn {
  border-radius: 4px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(0, 132, 142, 0.05) 100%
  );
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);

  p {
    color: rgb(0, 132, 142);
    font-size: 15px;
    font-weight: 500;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.relative-box {
  position: relative;

  span.counter-absolute {
    position: absolute;
    right: -20px;
    background: #f26500;
    color: #fff;
    font-weight: 500;
    padding: 5px 11px;
    border-radius: 50%;
    border: 5px solid #fff;
    top: -10px;
    z-index: 1;
  }
}
.move-up-text {
  position: relative;
  top: -4px;
}
.font-16px {
  font-size: 16px;
  font-weight: 500;
}
.font-15px {
  font-size: 15px;
  font-weight: 500;
}
.no-focus-border-btn {
  border: 0;
}
</style>

<style>
.move-out-expan-panels.theme--light.v-expansion-panels
  .v-expansion-panel:not(:first-child)::after {
  border-color: rgba(0, 0, 0, 0) !important;
}
.move-out-expan-panels.theme--light.v-expansion-panels
  .v-expansion-panel--active
  + .v-expansion-panel {
  margin-top: 5px;
}
.no-padding-panel > .v-expansion-panel-content__wrap {
  padding: unset !important;
}
</style>
