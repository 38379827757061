<template>
  <div class="intent-section px-7">
    <v-row>
      <status
        @resetStatus="successClear($options.name)"
        v-if="successHas($options.name)"
        :message="successGet($options.name)"
        status="success"
        class="col"
      ></status>
    </v-row>
    <div class="hb-font-header-2-medium mt-n1 mb-4">Prepare Space</div>
    <hb-card>
      <template v-slot:title>
        Please Inspect space #{{lease.Unit.number}} or Schedule an inspection.
        <v-spacer></v-spacer>
        <div :class="{'pt-3' : $vuetify.breakpoint.smAndDown}">
          <hb-btn
            small
            color="secondary"
            @click="show_reminder=true"
            :disabled="scheduledInspection"
          >Schedule Inspection</hb-btn>
        </div>
      </template>
      <v-row class="ma-0 pa-4 hb-text-light">Select one of the following:</v-row>
      <v-divider></v-divider>
      <v-col col="12" sm="10" class="pt-4 px-4 pb-1">
        <hb-radio-group class="ma-0" @change="setSelectedOption" v-model="defaultSelectedOption">
          <hb-radio
            :label="so.label"
            :value="so.value"
            :key="index"
            v-for="(so, index) in selectedOption"
            :disabled="scheduledInspection"
          ></hb-radio>
        </hb-radio-group>
      </v-col>
    </hb-card>
    <hb-card class="mt-3" title="Tenant has not moved out." v-show="setSelection.tenantNotMoveOut">
      <v-row
        class="ma-0 pa-4"
      >
        The Tenant has not moved-out according to their intent to Move-Out. Their lease will continue.
      </v-row>
    </hb-card>
    <hb-card class="mt-3 hb-elevation-medium" title="Clean and Ready to move-in checklist." v-show="setSelection.readyToMoveIn">
      <v-col col="12" sm="10" class="pa-4">
        <hb-checkbox
          :label="cl.label"
          :key="index"
          v-for="(cl, index) in checkList"
          v-model="cl.checked"
          :disabled="true"
          :class="{ 'mt-3' : index > 0 }"
        ></hb-checkbox> 
      </v-col>
    </hb-card>
    <hb-card class="mt-3 hb-elevation-medium" v-show="setSelection.spaceNeedsMaintenance">
      <template v-slot:title>
        Space Prep and/or Maintenance
        <v-spacer></v-spacer>
        <hb-btn
          small
          color="secondary"
          prepend-icon="mdi-printer"
          href="/files/inspection_sheet.pdf"
          target="_blank"
        >
          Print Move-Out Checklist
        </hb-btn>
      </template>
      <v-row class="ma-0 pa-3">
        <incomplete-prep
          :lease_id="lease.id"
          :property_id="property_id"
          :lease="lease" 
          :contact="contact"
          :moveout_prepare_space="true"
          @openBalance="setOpenBalance"
        />
      </v-row>
    </hb-card>
    <hb-card class="mt-3" title="Add Note" v-show="setSelection.spaceOccupied">
      <v-row class="ma-0 pa-4">
        <v-textarea
          :append-icon="pinned ? 'mdi-pin' : 'mdi-pin-off'"
          @click:append="pinned = !pinned"
          id="notes"
          name="notes"
          color="#101318"
          v-validate="'required|max:1000'"
          v-model="note"
          data-vv-scope="todo"
          rows="1"
          flat
          auto-grow
          placeholder="Enter Notes Here..."
          :hide-details="!errors.collect('todo.notes').length"
          :error-messages="errors.collect('todo.notes')"
        ></v-textarea>
      </v-row>
      <v-row class="ma-0 px-3 py-3">
        <v-col cols="8" class="pa-0 mt-n3">
            <status
                @resetStatus="successClear('note_added')"
                v-if="successHas('note_added')"
                :message="successGet('note_added')"
                status="success"
            ></status>
        </v-col>
        <v-col align="right">
          <hb-btn color="primary" @click="saveNote">Save</hb-btn>
        </v-col>
      </v-row>
    </hb-card>
    <div class="new-tenant-walkthrough-footer">
      <v-row>
        <v-col class="pt-4">
          <help-juice />
        </v-col>
        <v-col cols="9" class="d-flex align-center justify-end pr-sm-7">
          <hb-link class="mr-3" @click="$emit('close')" v-if="!showCloseButton">Cancel</hb-link>
          <hb-btn :class="{'mr-3' : showReviewMoveOutStatement}" v-if="showCloseButton" color="secondary" @click="$emit('close')">
            Close
          </hb-btn>
          <hb-btn v-if="showReviewMoveOutStatement" color="primary" @click="reviewMoveOutStatement" :disabled="reviewStatementBtnDisable">
            Review Move-Out Statement
          </hb-btn>
        </v-col>
      </v-row>
    </div>
    <todo
      v-model="show_reminder"
      v-if="show_reminder"
      :lease="lease"
      :contact="contact"
      title_text="Schedule Inspection"
      btn_text="Schedule Inspection"
      :moveout_prepare_space="true"
      @close="showReminder"
    >
    </todo>
  </div>
</template>
<script type="text/babel">
import Status from "../../includes/Messages.vue";
import api from "../../../assets/api.js";
import Todo from "./../Todo.vue";
import IncompletePrep from "./MoveOutStatement/IncompletePrep.vue";
import { EventBus } from '../../../EventBus.js';
import { notificationMixin } from  '../../../mixins/notificationMixin.js';
export default {
  name: "LeadData",
  mixins: [ notificationMixin ],
  data: () => ({
    selectedOption: [
      { label: "Tenant has not moved out.", value: "tenantNotMoveOut" },
      { label: "Space is clean and ready for move-in", value: "readyToMoveIn" },
      {
        label: "Space needs prep and/or maintenance",
        value: "spaceNeedsMaintenance"
      },
      {
        label: "Placed a lock on the space that appears to be occupied",
        value: "spaceOccupied"
      }
    ],
    checkList: [
      { label: "Floor Clean", value: "floorClean", checked: true },
      { label: "Wall is Clean", value: "wallClean", checked: true },
      { label: "Door is Clean and Works", value: "doorClean", checked: true },
      { label: "Hasp Works", value: "haspWorks", checked: true }
    ],
    setSelection: {
      tenantNotMoveOut: false,
      readyToMoveIn: true,
      spaceNeedsMaintenance: false,
      spaceOccupied: false
    },
    show_reminder: false,
    note: "",
    pinned: false,
    reviewStatementBtnDisable: false,
    scheduledInspection: false,
    defaultSelectedOption: 'readyToMoveIn'
  }),
  props: ["close", "next", "lease", "contact", "property_id"],
  components: { Todo, IncompletePrep, Status },
  created() {
    this.$emit('showChat');
  },
  computed:{
    showCloseButton() {
      return (this.setSelection.tenantNotMoveOut || this.setSelection.spaceOccupied || this.scheduledInspection) ? true : false;
    },
    showReviewMoveOutStatement() {
      this.reviewStatementBtnDisable = false;
      if(this.setSelection.readyToMoveIn || this.setSelection.spaceNeedsMaintenance) {
        return true;
      } else if( !this.setSelection.tenantNotMoveOut && !this.setSelection.spaceOccupied && !this.setSelection.readyToMoveIn && !this.setSelection.spaceNeedsMaintenance && !this.scheduledInspection) {
        this.reviewStatementBtnDisable = true;
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    reviewMoveOutStatement() {
      EventBus.$emit('ready_to_move_in', this.setSelection.readyToMoveIn);
      this.$emit('next');
    },
    setOpenBalance() {
      EventBus.$emit('invoice_generated');
    },
    setSelectedOption(option) {
      this.setSelection = {
        tenantNotMoveOut: false,
        readyToMoveIn: false,
        spaceNeedsMaintenance: false,
        lockOccupied: false
      };
      this.setSelection[option] = true;
    },
    showReminder(flag) {
      if(flag) {
        this.scheduledInspection = true;
        this.showMessageNotification({ type: 'success' , description: 'Scheduled Inspection Successfully!' })
      }
      this.show_reminder = false;

    },
    async saveNote() {
      let status = await this.$validator.validateAll("todo");
      if (!status) {
        return;
      }
      var data = {
        content: this.note,
        method: "note",
        created: new Date(),
        pinned: this.pinned,
        context: "moveout"
      };
      await api.post(
        this,
        api.CONTACTS + this.$props.contact.id + "/interaction",
        data
      ).then (r => {
        EventBus.$emit('communication::refresh', true);
        this.showMessageNotification({ type: 'success' , description: 'Note Added Successfully!' })
        this.note = "";
      });

    }
  }
};
</script>

<style scoped lang="scss">
.intent-section {
  padding: 20px 50px 75px 50px;
  margin-bottom: 75px;
}

.intent-section .new-tenant-walkthrough-footer {
  padding: 3px 0 3px 30px;
  background: #ffffff;
  border-top: 1px solid #e2e2e2;
  position: absolute;
  bottom: 0;
  width: 100%;
  z-index: 100;
  left: 0;
  right: 0;
}
.custom-heading {
  font-weight: 500;
  font-size: 16px;
}
.printer-moveout {
  color: #00848e;
  text-decoration: none;
  font-size: 12px;
  cursor: pointer;
}
.align-vertical-middle {
  vertical-align: middle !important;
}
.no-underline{
  text-decoration: none;
}
</style>

